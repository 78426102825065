import React, { useState, useEffect } from "react";
import "./CoQa.css";
import { BrowserRouter, Link, Route, Routes } from "react-router-dom";
import logo from "../../../LoginAssets/itdri.png";
import { MdLogout } from "react-icons/md";
import { AiOutlineHistory } from "react-icons/ai";
import { LuContact2, LuFileQuestion } from "react-icons/lu";
import { LuBookDown } from "react-icons/lu";
import { MdOutlineSlowMotionVideo } from "react-icons/md";
import { MdManageSearch } from "react-icons/md";
import { IoCreateOutline } from "react-icons/io5";
import DropFileInput from "./DropFileInput.js";
import Dropdown from "react-dropdown";
import axios from "axios";
import Select from "react-select";
import fileDownload from "js-file-download";
import htmlDocx from "html-docx-js/dist/html-docx";
import { MdSpatialAudio } from "react-icons/md";
import Spinner from "./SpinnerDs.js";

const CoQa = (props) => {
  const MainPageCoqa = () => {
    const [kontenVis, setKontenVis] = useState(true);
    const [loadingVis, setLoadingVis] = useState(false);
    const [hasilVis, setHasilVis] = useState(false);

    const [filteredData, setFilteredData] = useState([]);

    // Token & Status
    const [tokenCoqa, setTokenCoqa] = useState("");
    const [statusCoqa, setStatusCoqa] = useState("");

    // Inisiasi Interval 3 detik
    const [start, setStart] = useState(false);

    // Loading Spinner, Error, dan Proses upload
    const [loadingSpinner, setLoadingSpinner] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [prosesUpload, setProsesUpload] = useState("0");

    const [all_grup_kompetensi, setAll_grup_kompetensi] = useState([]);

    // DropDown Optional
    const [valgrup, setValGrup] = useState();
    const [valnama, setValNama] = useState();
    const [vallevel, setValLevel] = useState();

    // Opsi Sumber
    const [sumberOpsi, setSumberOpsi] = useState("database");

    // CheckBox Dropdown Search
    const [checked, setChecked] = useState(false);
    const [checkedMulti, setCheckedMulti] = useState(false);

    const [options_namakom, setNamakom] = useState([]);
    const options_levelkom = [
      "Fundamental",
      "Developing",
      "Intermediate",
      "Advanced",
      "Mastery",
    ];

    const stylesDropSearch = {
      valueContainer: (base) => ({
        ...base,
        maxHeight: 100,
        maxWidth: 450,
        overflowY: "auto",
      }),
    };

    // Menampung isian Referensi
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [optionsRef, setOptionsRef] = useState([]);

    // Tutup Dropdown
    const [classGrup, setClassGrup] = useState("");
    const [classNama, setClassNama] = useState("");
    const [classLevel, setClassLevel] = useState("");

    // Soal
    const [valSoal, setValSoal] = useState(20);

    // File
    const [files, setFiles] = useState("");
    const options_jenisInputan = ["Ebook (PDF)", `Modul (PPTX)`];
    const [jenisInputan, setJenisInputan] = useState(options_jenisInputan[0]);
    const [namaFile, setNamaFile] = useState("");

    const [logoInput, setLogoInput] = useState("pdf.png");
    const [dropname, setDropName] = useState("PDF");
    const [accFile, setAccFile] = useState(".pdf");

    const [removeStatus, setRemoveStatus] = useState(false);

    // const data = [
    //   {
    //     question: "Apa itu Computer Vision?",
    //     options: [
    //       "a) Teknologi untuk mengedit gambar",
    //       "b) Bidang ilmu yang mempelajari bagaimana komputer dapat memahami dan menafsirkan gambar digital",
    //       "c) Algoritma untuk kompresi gambar",
    //       "d) Perangkat keras untuk menangkap gambar",
    //     ],
    //     answer: "b",
    //   },
    //   {
    //     question:
    //       "Apa peran dari Convolutional Neural Network (CNN) dalam Computer Vision?",
    //     options: [
    //       "a) Untuk mengompresi gambar",
    //       "b) Untuk mengklasifikasikan dan mengenali pola dalam gambar",
    //       "c) Untuk mengedit video",
    //       "d) Untuk menyimpan data gambar",
    //     ],
    //     answer: "b",
    //   },
    //   {
    //     question: "Apa itu Image Segmentation dalam Computer Vision?",
    //     options: [
    //       "a) Proses menggabungkan beberapa gambar menjadi satu",
    //       "b) Proses memisahkan objek dalam gambar menjadi beberapa bagian",
    //       "c) Proses mengubah gambar menjadi hitam putih",
    //       "d) Proses mengompresi gambar",
    //     ],
    //     answer: "b",
    //   },
    //   {
    //     question: "Apa fungsi dari pooling layer dalam CNN?",
    //     options: [
    //       "a) Untuk mengurangi ukuran data dan kompleksitas komputasi",
    //       "b) Untuk meningkatkan resolusi gambar",
    //       "c) Untuk mengubah warna gambar",
    //       "d) Untuk menggabungkan beberapa gambar",
    //     ],
    //     answer: "a",
    //   },
    //   {
    //     question: "Apa itu Object Detection dalam Computer Vision?",
    //     options: [
    //       "a) Proses mengidentifikasi dan menentukan lokasi objek dalam gambar",
    //       "b) Proses mengubah ukuran gambar",
    //       "c) Proses mengedit gambar",
    //       "d) Proses menyimpan gambar",
    //     ],
    //     answer: "a",
    //   },
    //   {
    //     question:
    //       "Apa perbedaan antara Object Detection dan Object Recognition?",
    //     options: [
    //       "a) Object Detection mengidentifikasi objek, sementara Object Recognition menentukan lokasi objek",
    //       "b) Object Detection menentukan lokasi objek, sementara Object Recognition mengidentifikasi objek",
    //       "c) Tidak ada perbedaan",
    //       "d) Object Detection mengubah ukuran objek, sementara Object Recognition mengidentifikasi objek",
    //     ],
    //     answer: "b",
    //   },
    //   {
    //     question: "Apa itu Feature Extraction?",
    //     options: [
    //       "a) Proses menghapus fitur dari gambar",
    //       "b) Proses mengidentifikasi dan mengekstrak informasi penting dari gambar",
    //       "c) Proses mengubah warna gambar",
    //       "d) Proses mengompresi gambar",
    //     ],
    //     answer: "b",
    //   },
    //   {
    //     question: "Apa itu Deep Learning dalam konteks Computer Vision?",
    //     options: [
    //       "a) Metode untuk mengedit gambar",
    //       "b) Subset dari machine learning yang menggunakan jaringan saraf tiruan untuk memproses data gambar",
    //       "c) Algoritma untuk kompresi gambar",
    //       "d) Perangkat keras untuk menangkap gambar",
    //     ],
    //     answer: "b",
    //   },
    //   {
    //     question: "Apa itu Transfer Learning?",
    //     options: [
    //       "a) Proses mengubah gambar dari satu format ke format lain",
    //       "b) Teknik menggunakan model yang sudah dilatih pada satu tugas untuk tugas lain yang serupa",
    //       "c) Proses mengompresi gambar",
    //       "d) Proses mengedit gambar",
    //     ],
    //     answer: "b",
    //   },
    //   {
    //     question: "Apa itu Generative Adversarial Networks (GANs)?",
    //     options: [
    //       "a) Algoritma untuk mengompresi gambar",
    //       "b) Model yang terdiri dari dua jaringan saraf yang saling bersaing untuk menghasilkan data yang realistis",
    //       "c) Perangkat keras untuk menangkap gambar",
    //       "d) Metode untuk mengedit gambar",
    //     ],
    //     answer: "b",
    //   },
    //   {
    //     question: "Apa itu Optical Character Recognition (OCR)?",
    //     options: [
    //       "a) Teknologi untuk mengedit gambar",
    //       "b) Teknologi untuk mengenali teks dalam gambar",
    //       "c) Algoritma untuk kompresi gambar",
    //       "d) Perangkat keras untuk menangkap gambar",
    //     ],
    //     answer: "b",
    //   },
    //   {
    //     question: "Apa itu Image Classification?",
    //     options: [
    //       "a) Proses mengubah ukuran gambar",
    //       "b) Proses mengidentifikasi kategori atau kelas dari suatu gambar",
    //       "c) Proses mengedit gambar",
    //       "d) Proses menyimpan gambar",
    //     ],
    //     answer: "b",
    //   },
    //   {
    //     question: "Apa itu Image Augmentation?",
    //     options: [
    //       "a) Proses menghapus fitur dari gambar",
    //       "b) Proses memperbesar ukuran gambar",
    //       "c) Teknik untuk meningkatkan jumlah dan variasi data gambar dengan melakukan transformasi pada gambar asli",
    //       "d) Proses mengompresi gambar",
    //     ],
    //     answer: "c",
    //   },
    //   {
    //     question: "Apa itu Semantic Segmentation?",
    //     options: [
    //       "a) Proses menggabungkan beberapa gambar menjadi satu",
    //       "b) Proses memisahkan objek dalam gambar menjadi beberapa bagian dengan memberikan label pada setiap piksel",
    //       "c) Proses mengubah gambar menjadi hitam putih",
    //       "d) Proses mengompresi gambar",
    //     ],
    //     answer: "b",
    //   },
    //   {
    //     question: "Apa itu Edge Detection?",
    //     options: [
    //       "a) Proses mengidentifikasi tepi atau batas dalam gambar",
    //       "b) Proses mengubah ukuran gambar",
    //       "c) Proses mengedit gambar",
    //       "d) Proses menyimpan gambar",
    //     ],
    //     answer: "a",
    //   },
    //   {
    //     question: "Apa itu YOLO (You Only Look Once)?",
    //     options: [
    //       "a) Algoritma untuk mengompresi gambar",
    //       "b) Algoritma untuk deteksi objek real-time",
    //       "c) Perangkat keras untuk menangkap gambar",
    //       "d) Metode untuk mengedit gambar",
    //     ],
    //     answer: "b",
    //   },
    //   {
    //     question: "Apa itu ImageNet?",
    //     options: [
    //       "a) Algoritma untuk mengompresi gambar",
    //       "b) Database besar yang digunakan untuk pelatihan model dalam Computer Vision",
    //       "c) Perangkat keras untuk menangkap gambar",
    //       "d) Metode untuk mengedit gambar",
    //     ],
    //     answer: "b",
    //   },
    //   {
    //     question: "Apa itu ResNet?",
    //     options: [
    //       "a) Algoritma untuk mengompresi gambar",
    //       "b) Arsitektur jaringan saraf yang sangat dalam yang digunakan untuk mengatasi masalah vanishing gradient",
    //       "c) Perangkat keras untuk menangkap gambar",
    //       "d) Metode untuk mengedit gambar",
    //     ],
    //     answer: "b",
    //   },
    //   {
    //     question: "Apa itu HOG (Histogram of Oriented Gradients)?",
    //     options: [
    //       "a) Metode untuk mengubah ukuran gambar",
    //       "b) Metode untuk mendeskripsikan fitur dalam gambar berdasarkan orientasi gradien",
    //       "c) Proses mengedit gambar",
    //       "d) Proses menyimpan gambar",
    //     ],
    //     answer: "b",
    //   },
    //   {
    //     question: "Apa itu SIFT (Scale-Invariant Feature Transform)?",
    //     options: [
    //       "a) Algoritma untuk mengompresi gambar",
    //       "b) Algoritma untuk mendeteksi dan mendeskripsikan fitur lokal dalam gambar",
    //       "c) Perangkat keras untuk menangkap gambar",
    //       "d) Metode untuk mengedit gambar",
    //     ],
    //     answer: "b",
    //   },
    // ];

    // Mengambil 5 data pertama
    // const filteredData = data.slice(0, valSoal);

    // USE EFFECT

    // RESET ALL TOKEN

    useEffect(() => {
      props.reset();
    }, []);

    useEffect(() => {
      const getData = async () => {
        const hasil = await getAllKompetensi(5);
        setAll_grup_kompetensi(hasil);
        // console.log("INI HASILNYAAAA ", hasil);
      };

      getData();
    }, []);

    // Ambil Post API
    const getAllKompetensi = async (maxAttempts) => {
      let arrayStore = [];
      let attempts = 0;
      while (arrayStore.length === 0 && attempts < maxAttempts) {
        try {
          const response = await axios.post(
            process.env.REACT_APP_API_URL + "/all-grup-kompetensi/",
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          if (
            response.data.list_grup_kompetensi &&
            response.data.list_grup_kompetensi.length > 0
          ) {
            let data = response.data.list_grup_kompetensi;

            for (let i = 0; i < data.length; i++) {
              arrayStore.push(data[i][1]);
            }
            console.log("All Grup Kompetensi : " + arrayStore);
            attempts = maxAttempts;
          } else {
            console.log("Data kosong, mencoba lagi...");
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }

        attempts += 1;
      }

      if (arrayStore.length === 0) {
        console.log(`Gagal mendapatkan data setelah ${maxAttempts} percobaan.`);
      }

      return arrayStore;
    };

    const getKompetensiByGrupKompetensi = async (
      nama_grup_kompetensi,
      maxAttempts
    ) => {
      let arrayStore = [];
      let attempts = 0;
      const formData = new FormData();
      formData.append("nama_grup_kompetensi", nama_grup_kompetensi);

      while (arrayStore.length === 0 && attempts < maxAttempts) {
        try {
          const response = await axios.post(
            process.env.REACT_APP_API_URL +
              "/get-list-kompetensi-by-grup-kompetensi/",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          if (
            response.data.list_kompetensi &&
            response.data.list_kompetensi.length > 0
          ) {
            let data = response.data.list_kompetensi;

            for (let i = 0; i < data.length; i++) {
              arrayStore.push(data[i][1]);
            }
            console.log("All Nama Kompetensi : " + arrayStore);
            attempts = maxAttempts;
          } else {
            console.log("Data kosong, mencoba lagi...");
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }

        attempts += 1;
      }

      if (arrayStore.length === 0) {
        console.log(`Gagal mendapatkan data setelah ${maxAttempts} percobaan.`);
      }

      return arrayStore;
    };

    const getEbookReferenceByGrup = async (
      nama_grup_kompetensi,
      maxAttempts
    ) => {
      let arrayStore = [];
      let attempts = 0;
      const formData = new FormData();
      formData.append("grup_kompetensi", nama_grup_kompetensi);

      while (arrayStore.length === 0 && attempts < maxAttempts) {
        try {
          const response = await axios.post(
            process.env.REACT_APP_API_URL +
              "/get-all-ebook-name-by-grup-kompetensi/",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          if (response.data.list_ebook && response.data.list_ebook.length > 0) {
            let data = response.data.list_ebook;
            arrayStore = data.map((item, index) => ({
              value: "ref" + (index + 1).toString(),
              label: item[0],
            }));
            attempts = maxAttempts;
          } else {
            console.log("Data kosong, mencoba lagi...");
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }

        attempts += 1;
      }

      if (arrayStore.length === 0) {
        console.log(`Gagal mendapatkan data setelah ${maxAttempts} percobaan.`);
      }

      return arrayStore;
    };

    /////////////////////////////////////////////////////////////////////////////

    const handleChangeValGrup = (e) => {
      setValGrup(e.value);
      console.log("Value Grup Kom : ", e.value);

      const getNamaKom = async () => {
        const hasil = await getKompetensiByGrupKompetensi(e.value, 5);
        setValNama(hasil[0]);
        setNamakom(hasil);
      };

      const getEbook = async () => {
        const hasil = await getEbookReferenceByGrup(e.value, 5);
        setSelectedOptions("");
        setOptionsRef([...hasil]);
        console.log("ALL EBOOK : ", optionsRef);
      };

      getNamaKom();
      getEbook();
    };

    const handleChangeDropSearch = (selectedOptions) => {
      if (selectedOptions.length > 5) {
        alert("Saat ini Max 5 Referensi");
      } else {
        setSelectedOptions(selectedOptions);
      }
    };

    const handleChangeBox = () => {
      if (checked === false) {
        setChecked(true);
        setCheckedMulti(true);
      } else {
        if (selectedOptions.length > 1) {
          setSelectedOptions(selectedOptions[0]);
        }
        setChecked(false);
        setCheckedMulti(false);
        // selectedOptions.splice(0);
      }
    };

    const onFileChange = (e) => {
      setFiles(e[0]);
      console.log("Hasil upload : ", e[0]);
    };

    // Logic Dropdown Tidak Bisa Di Buka Bersamaan
    const handleDropdown1Click = () => {
      setClassGrup("dropdown_open");
      setClassNama("dropdown_close");
      setClassLevel("dropdown_close");
    };

    const handleDropdown2Click = () => {
      setClassGrup("dropdown_close");
      setClassNama("dropdown_open");
      setClassLevel("dropdown_close");
    };

    const handleDropdown3Click = () => {
      setClassGrup("dropdown_close");
      setClassNama("dropdown_close");
      setClassLevel("dropdown_open");
    };

    // Axios
    const coqaGenerate = async (formData, maxAttempts) => {
      let statusStore = "";
      let attempts = 0;

      const generating = async () => {
        try {
          const response = await axios.post(
            process.env.REACT_APP_API_URL + "/new-question-coqa/",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              timeout: 1200000 , // 20 menit
              onUploadProgress: (progressEvent) => {
                const percentCompleted = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );
                console.log(`Progress: ${percentCompleted}%`);
                if (percentCompleted < 60) {
                  setLoadingSpinner(true);
                  setProsesUpload(percentCompleted);
                }
                // Anda bisa menggunakan setState atau fungsi lain untuk memperbarui UI di sini
              },
            }
          );

          if (
            response.data.your_request_token &&
            response.data.your_request_token.length > 0
          ) {
            setLoadingSpinner(false);
            setLoadingVis(true);

            const data = response.data.your_request_token;
            const status = response.data.status;

            statusStore = data;

            // console.log("TOKEN NYA:", data);
            console.log("STATUS COQA NYA:", status);

            setTokenCoqa(data);
            setStatusCoqa(status);

            return true; // Selesai dengan sukses
          } else {
            console.log("Gagal Generate Soal, mencoba lagi...");
            return false; // Gagal, akan mencoba lagi
          }
        } catch (error) {
          console.error("Error message:", error.message);

          if (error.response) {
            console.error("Server responded with:", error.response.status);
            setErrorMsg("Server responded with:", error.response.status);
          } else if (error.request) {
            console.error("No response received:", error.request);
            setErrorMsg("No response received:", error.request);
          } else {
            console.error("Axios config error:", error.message);
            setErrorMsg("Axios config error:", error.message);
          }

          return false; // Error, akan mencoba lagi
        }
      };

      while (statusStore === "" && attempts < maxAttempts) {
        attempts += 1;
        const isSuccessful = await generating();

        if (isSuccessful) {
          setStart(true);
          break;
        } else if (attempts < maxAttempts) {
          console.log(
            `Menunggu 5 detik sebelum mencoba lagi (${attempts}/${maxAttempts})...`
          );
          await new Promise((resolve) => setTimeout(resolve, 5000));
        }
      }

      if (statusStore === "") {
        console.log(`Gagal Generate Soal setelah ${maxAttempts} percobaan.`);
      }

      return statusStore;
    };

    const handleClickGenerate = (e) => {
      // Something
      e.preventDefault();

      console.log("Grup Kompetensi : ", valgrup);
      console.log("Nama Kompetensi : ", valnama);
      console.log("Level Kompetensi : ", vallevel);
      console.log("Ref : ", selectedOptions);

      console.log("Jenis Inputan : ", jenisInputan);
      console.log("Jumlah Soal : ", valSoal);
      console.log("Opsi Referensi : ", sumberOpsi);

      if (sumberOpsi === "database") {
        if (
          valgrup === undefined || valgrup === "" ||
          valnama === undefined || valnama === "" ||
          vallevel === undefined || vallevel === ""
        ) {
          alert("Kompetensi dan Level Wajib di Isikan");
        } else {
          if (selectedOptions === "") {
            alert("Harap Isi Referensi Terlebih Dahulu");
          } else if (selectedOptions) {
            // setLoadingVis(true);
            setKontenVis(false);

            setNamaFile(files.name);

            // Sumber Database
            const formData = new FormData();
            formData.append("nama_kompetensi", valnama);
            formData.append("level", vallevel);
            formData.append("jumlah_soal", valSoal);

            if (selectedOptions.length == undefined) {
              formData.append("reference_1", selectedOptions.label);
            } else if (selectedOptions.length > 0) {
              selectedOptions.forEach((item, index) => {
                formData.append(`reference_${index + 1}`, item.label);
              });
            }
            formData.append("id_user", props.user.id_user);

            const callCoQa = async () => {
              const hasil = await coqaGenerate(formData, 5);
              if (hasil === "" || hasil === null) {
                alert("GAGAL GENERATE SOAL COQA => " + errorMsg);
              }
            };

            callCoQa();
          }
        }
      } else {
        if (
          valgrup === undefined || valgrup === "" ||
          valnama === undefined || valnama === "" ||
          vallevel === undefined || vallevel === ""
        ) {
          alert("Kompetensi dan Level Wajib di Isikan");
        } else {
          if (files === "" || files === undefined) {
            alert("Harap Isi Referensi Terlebih Dahulu");
          } else {
            // setLoadingVis(true);
            setKontenVis(false);
            setNamaFile(files.name);

            // SUMBER LOKAL
            const formData = new FormData();

            if (jenisInputan === "Ebook (PDF)") {
              console.log("MASUKK PDF");
              formData.append("file_pdf", files);
            } else if (jenisInputan === "Modul (PPTX)") {
              formData.append("file_pptx", files);
            }

            formData.append("nama_kompetensi", valnama);
            formData.append("level", vallevel);
            formData.append("jumlah_soal", valSoal);
            formData.append("id_user", props.user.id_user);

            const callCoQa = async () => {
              const hasil = await coqaGenerate(formData, 5);
              if (hasil === "" || hasil === null) {
                alert("GAGAL GENERATE SOAL COQA => " + errorMsg);
              }
            };

            callCoQa();
          }
        }
      }
    };

    ////////////////////////////////////////////////////////////////////////////////////////////
    // Ambil Status Generate per interval 3 detik

    const fetchData = async () => {
      const formData = new FormData();
      formData.append("request_token", tokenCoqa);

      // console.log("Token CoQa : ", tokenCoqa);
      try {
        const res = await axios.post(
          process.env.REACT_APP_API_URL + "/result-question-coqa/",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            timeout: 360000, // Timeout diatur ke 0 untuk menunggu sampai selesai
          }
        );
        let status = res.data.status;
        let data = res.data.result.questions;

        console.log("Status : ", status);
        console.log("Data : ", data);

        setStatusCoqa(status);

        if (status === "done") {
          console.log("Proses Selesai");
          // const newData = data.slice(0, valSoal);
          setStart(false);
          setFilteredData(data);
        }
      } catch (error) {
        console.error("Error saat memanggil API:", error);
      }
    };

    useEffect(() => {
      let interval;
      console.log("TERPANGGIL");

      if (start) {
        interval = setInterval(async () => {
          await fetchData(); // Call the fetchData function here
        }, 3000);
      }

      return () => clearInterval(interval);
    }, [start, tokenCoqa]);

    // SETTINGS DENGAN STATUS
    useEffect(() => {
      console.log("Settings Loading dengan Status");
      console.log("+" + statusCoqa + "+");
      // console.log("Token Req : ", statusCoqa);

      if (statusCoqa === "done") {
        console.log("DONEEEEEEEEEEEEEEEEEEEEEE");
        setKontenVis(false);
        setHasilVis(true);
        setLoadingVis(false);

        setTokenCoqa("");
        setStatusCoqa("");
      }
      // Jika selain "Done"
      else if (statusCoqa === "processing") {
        console.log("PROCESINGGGGGGGGGGGGGG");
        setKontenVis(false);
        setHasilVis(false);
        setLoadingVis(true);
        setLoadingSpinner(false);
      } else if (statusCoqa === "waiting") {
        console.log("WAITINGGGGGGGGGGGGGGGGGGGGGGG");
        setKontenVis(false);
        setHasilVis(false);
        setLoadingVis(true);
        setLoadingSpinner(false);
      }
    }, [statusCoqa]);
    ////////////////////////////////////////////////////////////////////////////////////////////
    // SET SHOW TABLE DARI REQUEST
    const fetchDataReq = async () => {
      const formData = new FormData();
      formData.append("request_token", props.tokenCoqa);

      console.log("Detail Mapping : ", props.detailCoqa);

      // console.log("Token Mapping : ", tokenCoqa);
      try {
        const res = await axios.post(
          process.env.REACT_APP_API_URL + "/result-question-coqa/",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            timeout: 360000, // Timeout diatur ke 0 untuk menunggu sampai selesai
          }
        );
        let status = res.data.status;
        let data = res.data.result.questions;

        console.log("Status : ", status);
        console.log("Data : ", data);

        setStatusCoqa(status);

        if (status === "done") {
          console.log("Proses Selesai");
          // const newData = data.slice(0, valSoal);
          setStart(false);
          setFilteredData(data);
        }
      } catch (error) {
        console.error("Error saat memanggil API:", error);
      }
    };

    useEffect(() => {
      if (props.tokenCoqa.length > 1) {
        setTokenCoqa(props.tokenCoqa);
      }
    }, [props.tokenCoqa]);

    useEffect(() => {
      if (props.tokenCoqa === undefined || props.tokenCoqa === "") {
        // Nothing
      } else {
        // console.log("INI TOKEN REQQQQQQQQQ : " + props.tokenCoqa);
        console.log("INI STATUS REQQQQQQQQ : " + props.statusCoqa);
        console.log("INI DETAIL COQA : ", props.detailCoqa);

        if (props.tokenCoqa.length > 5) {
          console.log("ADA TOKEN REQ NIHHH");

          if (props.statusCoqa !== "done") {
            setStart(true);
          } else {
            fetchDataReq();
          }
          setValNama(props.detailCoqa.nama_kompetensi);
          setValLevel(
            props.detailCoqa.level.charAt(0).toUpperCase() +
              props.detailCoqa.level.slice(1).toLowerCase()
          );

          if (props.statusCoqa === "error") {
            props.resetToken("");
            props.resetStatus("");
            props.resetDetail("");
            alert("STATUS ERROR : REFERENSI BERMASALAH");
          } else if (props.statusCoqa === "processing") {
            setKontenVis(false);
            setHasilVis(false);
            setLoadingVis(true);
          } else if (props.statusCoqa === "waiting") {
            setKontenVis(false);
            setHasilVis(false);
            setLoadingVis(true);
          } else if (props.statusCoqa === "done") {
            setKontenVis(false);
            setHasilVis(true);
            setLoadingVis(false);
          }
        } else {
          // Nothing
        }
      }
    }, [props.tokenCoqa]);

    ////////////////////////////////////////////////////////////////////////////////////////////

    const handleClickDownload = (e) => {
      e.preventDefault();
      const content = document.getElementById("content").innerHTML;
      const converted = htmlDocx.asBlob(content);
      console.log(converted);
      fileDownload(converted, "document.docx");
    };

    const handleClickBaru = (e) => {
      // Something
      e.preventDefault();
      setKontenVis(true);
      setHasilVis(false);

      // Konten Null
      setValGrup("");
      setValNama("");
      setValLevel("");
      setNamakom("");
      setOptionsRef([]);
      setSelectedOptions([]);
      setValSoal(20);
      setFiles("");
    };

    // OPSI SUMBER

    const handleOptionChange = (event) => {
      setSumberOpsi(event.target.value);
    };

    const handleJenisChange = (e) => {
      setJenisInputan(e.value);
      console.log(e.value);

      setRemoveStatus(true);

      if (e.value.includes("Ebook")) {
        setLogoInput("pdf.png");
        setDropName("PDF");
        setAccFile(".pdf");
      } else if (e.value.includes("Modul")) {
        setLogoInput("pptx.png");
        setDropName("PPTX");
        setAccFile(".pptx");
      }
    };

    const handleResetRemove = () => {
      setRemoveStatus(false);

      // setData([]);
      // setTimeout(() => {
      //   setData([{ no: 1, kompetensi: "-", level: "-", score: "-" }]);
      // }, 0);
    };

    const onChangeSoal = (e) => {
      const inputValue = e.target.value;

      // Jika input kosong, biarkan kosong
      if (inputValue === "") {
        setValSoal("");
        return;
      }

      // Mengubah nilai input menjadi angka
      const newVal = parseInt(inputValue, 10);

      // Validasi input angka
      if (isNaN(newVal)) {
        setValSoal(20);
      } else if (newVal > 50) {
        setValSoal(50);
        alert("Mencegah Processing Lama, Max 50 Soal");
      } else if (newVal < 1) {
        setValSoal(1);
      } else {
        setValSoal(newVal);
      }
    };

    const handleBlur = (e) => {
      if (e.target.value === "") {
        setValSoal(20);
      } else if (e.target.value < 1) {
        setValSoal(1);
      }
    };

    return (
      <div>
        {kontenVis && (
          <div>
            <h1 className="judul_coqa_konten">CoQa</h1>
            <div className="fullpage_coqa">
              <div className="first_content_coqa">
                <span className="span-items_coqa">Grup Kompetensi</span>
                <span className="required"> *</span>
                <div onClick={handleDropdown1Click}>
                  <Dropdown
                    // id={`dd-grupKompetensi${toggleReview}`}
                    // ref={inputRef}
                    className={`dropDown-content-grup ${classGrup}`}
                    options={all_grup_kompetensi}
                    placeholder="Select an option"
                    onChange={(e) => handleChangeValGrup(e)}
                  />
                </div>

                <span className="span-items_coqa">Nama Kompetensi</span>
                <span className="required"> *</span>
                <div onClick={handleDropdown2Click}>
                  <Dropdown
                    className={`dropDown-content-nama ${classNama}`}
                    options={options_namakom}
                    placeholder="Select an option"
                    value={valnama}
                    onChange={(e) => setValNama(e.value)}
                  />
                </div>

                <span className="span-items_coqa">Level Kompetensi</span>
                <span className="required"> *</span>
                <div onClick={handleDropdown3Click}>
                  <Dropdown
                    // className={`dropDown-content-level${addStyleLevel}`}
                    className={`dropDown-content-level ${classLevel}`}
                    options={options_levelkom}
                    placeholder="Select an option"
                    onChange={(e) => setValLevel(e.value)}
                  />
                </div>
              </div>

              <div className="second_content_coqa">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                  }}
                >
                  <div className="soal_coqa">
                    <span className="span-items_coqa">Jumlah Soal</span>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <input
                        type="number"
                        className="inputText_coqa"
                        value={valSoal}
                        max={50}
                        min={1}
                        onChange={onChangeSoal}
                        onBlur={handleBlur}
                        onKeyDown={(e) => {
                          if (["e", ".", ","].includes(e.key)) {
                            e.preventDefault();
                          }
                        }}
                      ></input>
                    </div>
                  </div>

                  <div style={{ marginLeft: "50px" }}>
                    <div>
                      <span className="span-items_coqa">Referensi</span>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        marginTop: "10px",
                        fontSize: "15px",
                      }}
                    >
                      <label
                        style={{
                          marginRight: "15px",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <input
                          type="radio"
                          value="database"
                          checked={sumberOpsi === "database"}
                          onChange={handleOptionChange}
                          style={{ marginRight: "5px" }}
                        />
                        Database Marshall
                      </label>

                      <label
                        style={{
                          marginRight: "15px",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <input
                          type="radio"
                          value="local"
                          checked={sumberOpsi === "local"}
                          onChange={handleOptionChange}
                          style={{ marginRight: "5px" }}
                        />
                        Upload File
                      </label>
                    </div>
                  </div>
                </div>

                {sumberOpsi === "database" ? (
                  <div className="scroll-container_coqa">
                    <span className="span-items_coqa">Database Marshall</span>
                    {optionsRef.length < 1 ? (
                      <div>
                        <span style={{ fontSize: "12px" }}>
                          * Database Kompetensi Ini Belum Terisi, Upload Ebook /
                          Pilih Upload File
                        </span>
                      </div>
                    ) : (
                      <div>
                        <Select
                          className="dropdown_search_coqa"
                          options={optionsRef}
                          value={selectedOptions}
                          onChange={handleChangeDropSearch}
                          isMulti={checkedMulti}
                          maxMenuHeight={150}
                          styles={stylesDropSearch}
                          placeholder={"Pilih Referensi Ebook .."}
                          noOptionsMessage={() => "Ebook Tidak di temukan"}
                        />
                        <div className="div-multiselect_coqa">
                          <input
                            className="checkBox_multiselect_coqa"
                            type="checkbox"
                            value={checked}
                            onChange={handleChangeBox}
                          />
                          <span
                            style={{
                              marginLeft: "5px",
                              marginTop: "5px",
                              fontSize: "10px",
                            }}
                          >
                            Multi Select?
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="file_coqa">
                    <div>
                      <span className="span-items_coqa">Jenis Inputan</span>
                      <Dropdown
                        className="dropDown-jenisInputan"
                        options={options_jenisInputan}
                        value={jenisInputan}
                        onChange={(e) => handleJenisChange(e)}
                      />
                    </div>

                    <div style={{ marginTop: "20px" }}>
                      <div>
                        <span className="span-items_coqa">File</span>
                        <span
                          className="span-items_coqa"
                          style={{ color: "red" }}
                        >
                          *
                        </span>
                      </div>

                      <DropFileInput
                        onFileChange={(e) => onFileChange(e)}
                        textjudul="Mapping"
                        width="500"
                        height="100"
                        widthlogo="25"
                        logoName={logoInput}
                        dropName={dropname}
                        accept={accFile}
                        multiple={false}
                        removeAll={removeStatus}
                        resetRemove={handleResetRemove}
                      />
                    </div>

                    {/* <div>
                    <span className="span-items_coqa">File Module PPT</span>
                    <DropFileInput
                      onFileChange={(e) => onFileChange(e)}
                      textjudul="CoQa"
                      width="400"
                      height="100"
                      widthlogo="25"
                      logoName="pptx.png"
                      dropName="PPTX"
                      accept=".pptx"
                      multiple={false}
                    />
                  </div>

                  <div style={{ marginLeft: "20px" }}>
                    <span className="span-items_coqa">
                      File Ebook Reference PDF (Optional)
                    </span>
                    <DropFileInput
                      onFileChange={(e) => onFileEbookChange(e)}
                      textjudul="CoQa"
                      width="400"
                      height="100"
                      widthlogo="25"
                      logoName="pdf.png"
                      dropName="PDF"
                      accept=".pdf"
                      multiple={true}
                    />
                  </div> */}
                  </div>
                )}

                <div className="btnCoqa">
                  <button
                    className="coqaBut"
                    onClick={(e) => handleClickGenerate(e)}
                  >
                    Generate Soal
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {loadingSpinner && (
          <div
            style={{
              display: "grid",
              justifyContent: "center",
              alignContent: "center",
              height: "70vh",
            }}
          >
            <span style={{ fontSize: "15px" }}>
              Masih Belum Masuk Antrian (Tunggu Upload Selesai)
            </span>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span style={{ fontSize: "15px" }}>
                Progress Upload : {prosesUpload}% &nbsp;&nbsp;&nbsp;
              </span>
              <Spinner />
            </div>
          </div>
        )}

        {loadingVis && (
          <div
            style={{
              display: "grid",
              justifyContent: "center",
              alignContent: "center",
              height: "70vh",
            }}
          >
            <span style={{ fontSize: "20px" }}>Generate Soal CoQa ...</span>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
              }}
            >
              <Spinner />
            </div>
          </div>
        )}

        {hasilVis && (
          <div>
            <h1 className="judul_coqa_hasil">CoQa</h1>

            <div className="fullpage_hasilcoqa">
              <span className="span-items_hasilcoqa">
                Kompetensi : {valnama}
              </span>
              <span className="span-items_hasilcoqa">Level : {vallevel}</span>
              <div className="content_hasilcoqa" id="content">
                {filteredData.map((item, index) => (
                  <div key={index} className="question-box">
                    <h3>
                      {index + 1}. {item.question}
                    </h3>
                    <br />
                    <ul>
                      {item.options.map((option, idx) => (
                        <li key={idx}>{option}</li>
                      ))}
                    </ul>
                    <p>
                      <strong>Jawaban : {item.answer}</strong>
                    </p>
                  </div>
                ))}
              </div>

              <div className="btnCoqa">
                <button
                  className="coqaButDownload"
                  onClick={(e) => handleClickDownload(e)}
                >
                  Download as Word
                </button>
                <button className="coqaBut" onClick={(e) => handleClickBaru(e)}>
                  Question Baru
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="container">
      <nav className="sidebar">
        <ul>
          <li>
            <div className="logoMarshall">
              <img src={logo} alt=""></img>
              <span className="nav-item">Marshall</span>
            </div>
          </li>
          <li>
            <Link to={"/dashboard"}>
              <IoCreateOutline className="fas" />
              <span className="nav-item">Create</span>
            </Link>
          </li>
          <li>
            <Link to={"/requests"}>
              <AiOutlineHistory className="fas" />
              <span className="nav-item">View Requests History</span>
            </Link>
          </li>
          <li>
            <Link to={"/eLibrary"}>
              <LuBookDown className="fas" />
              <span className="nav-item">E-Library</span>
            </Link>
          </li>
          <li>
            <Link to={"/coqa"}>
              <LuFileQuestion className="fas" />
              <span className="nav-item">CoQa</span>
            </Link>
          </li>
          <li>
            <Link to={"/mapping"}>
              <MdManageSearch className="fas" />
              <span className="nav-item">Mapping Dirkom 11</span>
            </Link>
          </li>
          <li>
            <Link to={"/audiolearning"}>
              <MdSpatialAudio className="fas" />
              <span className="nav-item">Audio Learning</span>
            </Link>
          </li>
          {/* <li>
            <Link to={"/videolearning"}>
              <MdOutlineSlowMotionVideo className="fas" />
              <span className="nav-item">Video Learning</span>
            </Link>
          </li> */}
          <li>
            <Link to={"/contactus"}>
              <LuContact2 className="fas" />
              <span className="nav-item">Contact Us</span>
            </Link>
          </li>
          <li className="logout">
            <Link to={"/"}>
              <MdLogout className="fas" />
              <span className="nav-item-logout">Logout</span>
            </Link>
          </li>
        </ul>
      </nav>

      <section className="main">
        <div className="main-coqa">
          <div className="container_coqa">
            <MainPageCoqa />
          </div>
        </div>
      </section>

      <span
        style={{
          display: "flex",
          position: "absolute",
          bottom: "0",
          right: "0",
          padding: "5px",
        }}
      >
        ~ Beta Version 1.0
      </span>
    </div>
  );
};

export default CoQa;
