import React, { useState, useEffect, useRef } from "react";
// import DashboardCSS from "./Dashboard.module.css";
import "../Dashboard.css";
import "./Requests.css";
import Spinner from "./SpinnerDs.js";
import { BrowserRouter, Link, Route, Routes } from "react-router-dom";
import logo from "../../../LoginAssets/itdri.png";
import { BsGear, BsQuestionSquare } from "react-icons/bs";
import { MdLogout } from "react-icons/md";
import { FaUser } from "react-icons/fa";
import { IoCreateOutline } from "react-icons/io5";
import { AiOutlineHistory } from "react-icons/ai";
import { HiMenuAlt3 } from "react-icons/hi";
import { GrView } from "react-icons/gr";
import { GoHistory } from "react-icons/go";
import { LuBookDown, LuContact2 } from "react-icons/lu";
import { LuFileQuestion } from "react-icons/lu";
import { MdOutlineSlowMotionVideo } from "react-icons/md";
import { MdManageSearch } from "react-icons/md";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Pagination from "./Pagination.js";
import Popup from "./PopUp.js";
import EditEbook from "./EditEbook.js";
import { MdSpatialAudio } from "react-icons/md";
import Dropdown from "react-dropdown";

const Requests = (props) => {
  // Data untuk tabel
  const [currentPage, setCurrentPage] = useState(1);
  const [dataTable, setDataTable] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const navigate = useNavigate();

  // Pop Up
  const [helpPopup, setHelpPopup] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  // Kebutuhan Filter Type
  const [dataTipeFull, setDataTipeFull] = useState();
  const [dataReview, setDataReview] = useState();
  const [dataGenerate, setDataGenerate] = useState();
  const [dataEbook, setDataEbook] = useState();
  const [dataCoqa, setDataCoqa] = useState();
  const [dataMappingM, setDataMappingM] = useState();
  const [dataMappingE, setDataMappingE] = useState();
  const [dataAudioM, setDataAudioM] = useState();
  const [dataAudioE, setDataAudioE] = useState();

  const [dataStatusFull, setDataStatusFull] = useState();
  const [dataDone, setDataDone] = useState();
  const [dataProcess, setDataProcess] = useState();
  const [dataWait, setDataWait] = useState();
  const [dataError, setDataError] = useState();
  const [dataCancel, setDataCancel] = useState();

  const [dataTableFull, setDataTableFull] = useState([]);

  const options_jenisTipe = [
    `Semua (${dataTipeFull})`,
    `Review Modul (${dataReview})`,
    `Generate Modul (${dataGenerate})`,
    `Extract Ebook (${dataEbook})`,
    `Generate CoQa (${dataCoqa})`,
    `Mapping Module (${dataMappingM})`,
    `Mapping Ebook (${dataMappingE})`,
    `Module to Audio (${dataAudioM})`,
    `Ebook to Audio (${dataAudioE})`,
  ];

  const options_jenisStatus = [
    `Semua (${dataStatusFull})`,
    `Done (${dataDone})`,
    `Processing (${dataProcess})`,
    `Waiting (${dataWait})`,
    `Error (${dataError})`,
    `Cancelled (${dataCancel})`,
  ];

  const [valjenisStatus, setValJenisStatus] = useState(`Pilih Status`);
  const [valjenisTipe, setValJenisTipe] = useState(`Pilih Tipe`);

  const handleJenisChange = (e) => {
    console.log(e.value.split("(")[0].trim());
    const filter = e.value.split("(")[0].trim();

    setValJenisStatus("Pilih Status");

    const nullObj = [
      {
        id: "-",
        detail: "-",
        strdetail: "-",
        request_token: "-",
        request_date: "-",
        request_status: "-",
        request_type: "-",
        user: "-",
      },
    ];

    setValJenisTipe(e.value);

    if (filter === "Semua") {
      setDataTable(dataTableFull);
      setCountReq(dataTableFull.length);
      setDataStatusFull(dataTableFull.length);

      // Set Data Status
      const filterdone = dataTableFull.filter(
        (item) => item.request_status.toLowerCase() === "done"
      );
      setDataDone(filterdone.length);

      const filterprocess = dataTableFull.filter(
        (item) => item.request_status.toLowerCase() === "processing"
      );
      setDataProcess(filterprocess.length);

      const filterwait = dataTableFull.filter(
        (item) => item.request_status.toLowerCase() === "waiting"
      );
      setDataWait(filterwait.length);

      const filtererror = dataTableFull.filter(
        (item) => item.request_status.toLowerCase() === "error"
      );
      setDataError(filtererror.length);

      const filtercancel = dataTableFull.filter(
        (item) => item.request_status.toLowerCase() === "cancelled"
      );
      setDataCancel(filtercancel.length);
    } else if (filter === "Review Modul") {
      const filterData1 = dataTableFull
        .filter((item) => item.request_type.toLowerCase() === "review modul")
        .map((item, index) => ({
          ...item, // Menyalin semua properti dari item
          id: index + 1, // Update id mulai dari 1
        }));
      if (filterData1.length < 1) {
        setDataTable(nullObj);
        setCountReq(nullObj.length);
      } else {
        setDataTable(filterData1);
        setCountReq(filterData1.length);
        setDataStatusFull(filterData1.length);

        // Set Data Status
        const filterdone = filterData1.filter(
          (item) => item.request_status.toLowerCase() === "done"
        );
        setDataDone(filterdone.length);

        const filterprocess = filterData1.filter(
          (item) => item.request_status.toLowerCase() === "processing"
        );
        setDataProcess(filterprocess.length);

        const filterwait = filterData1.filter(
          (item) => item.request_status.toLowerCase() === "waiting"
        );
        setDataWait(filterwait.length);

        const filtererror = filterData1.filter(
          (item) => item.request_status.toLowerCase() === "error"
        );
        setDataError(filtererror.length);

        const filtercancel = filterData1.filter(
          (item) => item.request_status.toLowerCase() === "cancelled"
        );
        setDataCancel(filtercancel.length);
      }
    } else if (filter === "Generate Modul") {
      const filterData2 = dataTableFull
        .filter((item) => item.request_type.toLowerCase() === "generate modul")
        .map((item, index) => ({
          ...item, // Menyalin semua properti dari item
          id: index + 1, // Update id mulai dari 1
        }));
      if (filterData2.length < 1) {
        setDataTable(nullObj);
        setCountReq(nullObj.length);
      } else {
        setDataTable(filterData2);
        setCountReq(filterData2.length);
        setDataStatusFull(filterData2.length);

        // Set Data Status
        const filterdone = filterData2.filter(
          (item) => item.request_status.toLowerCase() === "done"
        );
        setDataDone(filterdone.length);

        const filterprocess = filterData2.filter(
          (item) => item.request_status.toLowerCase() === "processing"
        );
        setDataProcess(filterprocess.length);

        const filterwait = filterData2.filter(
          (item) => item.request_status.toLowerCase() === "waiting"
        );
        setDataWait(filterwait.length);

        const filtererror = filterData2.filter(
          (item) => item.request_status.toLowerCase() === "error"
        );
        setDataError(filtererror.length);

        const filtercancel = filterData2.filter(
          (item) => item.request_status.toLowerCase() === "cancelled"
        );
        setDataCancel(filtercancel.length);
      }
    } else if (filter === "Extract Ebook") {
      const filterData3 = dataTableFull
        .filter((item) => item.request_type.toLowerCase() === "extract ebook")
        .map((item, index) => ({
          ...item, // Menyalin semua properti dari item
          id: index + 1, // Update id mulai dari 1
        }));
      if (filterData3.length < 1) {
        setDataTable(nullObj);
        setCountReq(nullObj.length);
      } else {
        setDataTable(filterData3);
        setCountReq(filterData3.length);
        setDataStatusFull(filterData3.length);

        // Set Data Status
        const filterdone = filterData3.filter(
          (item) => item.request_status.toLowerCase() === "done"
        );
        setDataDone(filterdone.length);

        const filterprocess = filterData3.filter(
          (item) => item.request_status.toLowerCase() === "processing"
        );
        setDataProcess(filterprocess.length);

        const filterwait = filterData3.filter(
          (item) => item.request_status.toLowerCase() === "waiting"
        );
        setDataWait(filterwait.length);

        const filtererror = filterData3.filter(
          (item) => item.request_status.toLowerCase() === "error"
        );
        setDataError(filtererror.length);

        const filtercancel = filterData3.filter(
          (item) => item.request_status.toLowerCase() === "cancelled"
        );
        setDataCancel(filtercancel.length);
      }
    } else if (filter === "Generate CoQa") {
      const filterData4 = dataTableFull
        .filter((item) => item.request_type.toLowerCase() === "generate coqa")
        .map((item, index) => ({
          ...item, // Menyalin semua properti dari item
          id: index + 1, // Update id mulai dari 1
        }));
      if (filterData4.length < 1) {
        setDataTable(nullObj);
        setCountReq(nullObj.length);
      } else {
        setDataTable(filterData4);
        setCountReq(filterData4.length);
        setDataStatusFull(filterData4.length);

        // Set Data Status
        const filterdone = filterData4.filter(
          (item) => item.request_status.toLowerCase() === "done"
        );
        setDataDone(filterdone.length);

        const filterprocess = filterData4.filter(
          (item) => item.request_status.toLowerCase() === "processing"
        );
        setDataProcess(filterprocess.length);

        const filterwait = filterData4.filter(
          (item) => item.request_status.toLowerCase() === "waiting"
        );
        setDataWait(filterwait.length);

        const filtererror = filterData4.filter(
          (item) => item.request_status.toLowerCase() === "error"
        );
        setDataError(filtererror.length);

        const filtercancel = filterData4.filter(
          (item) => item.request_status.toLowerCase() === "cancelled"
        );
        setDataCancel(filtercancel.length);
      }
    } else if (filter === "Mapping Module") {
      const filterData5 = dataTableFull
        .filter((item) => item.request_type.toLowerCase() === "mapping module")
        .map((item, index) => ({
          ...item, // Menyalin semua properti dari item
          id: index + 1, // Update id mulai dari 1
        }));
      if (filterData5.length < 1) {
        setDataTable(nullObj);
        setCountReq(nullObj.length);
      } else {
        setDataTable(filterData5);
        setCountReq(filterData5.length);
        setDataStatusFull(filterData5.length);

        // Set Data Status
        const filterdone = filterData5.filter(
          (item) => item.request_status.toLowerCase() === "done"
        );
        setDataDone(filterdone.length);

        const filterprocess = filterData5.filter(
          (item) => item.request_status.toLowerCase() === "processing"
        );
        setDataProcess(filterprocess.length);

        const filterwait = filterData5.filter(
          (item) => item.request_status.toLowerCase() === "waiting"
        );
        setDataWait(filterwait.length);

        const filtererror = filterData5.filter(
          (item) => item.request_status.toLowerCase() === "error"
        );
        setDataError(filtererror.length);

        const filtercancel = filterData5.filter(
          (item) => item.request_status.toLowerCase() === "cancelled"
        );
        setDataCancel(filtercancel.length);
      }
    } else if (filter === "Mapping Ebook") {
      const filterData6 = dataTableFull
        .filter((item) => item.request_type.toLowerCase() === "mapping ebook")
        .map((item, index) => ({
          ...item, // Menyalin semua properti dari item
          id: index + 1, // Update id mulai dari 1
        }));
      if (filterData6.length < 1) {
        setDataTable(nullObj);
        setCountReq(nullObj.length);
      } else {
        setDataTable(filterData6);
        setCountReq(filterData6.length);
        setDataStatusFull(filterData6.length);

        // Set Data Status
        const filterdone = filterData6.filter(
          (item) => item.request_status.toLowerCase() === "done"
        );
        setDataDone(filterdone.length);

        const filterprocess = filterData6.filter(
          (item) => item.request_status.toLowerCase() === "processing"
        );
        setDataProcess(filterprocess.length);

        const filterwait = filterData6.filter(
          (item) => item.request_status.toLowerCase() === "waiting"
        );
        setDataWait(filterwait.length);

        const filtererror = filterData6.filter(
          (item) => item.request_status.toLowerCase() === "error"
        );
        setDataError(filtererror.length);

        const filtercancel = filterData6.filter(
          (item) => item.request_status.toLowerCase() === "cancelled"
        );
        setDataCancel(filtercancel.length);
      }
    } else if (filter === "Module to Audio") {
      const filterData7 = dataTableFull
        .filter((item) => item.request_type.toLowerCase() === "module to audio")
        .map((item, index) => ({
          ...item, // Menyalin semua properti dari item
          id: index + 1, // Update id mulai dari 1
        }));
      if (filterData7.length < 1) {
        setDataTable(nullObj);
        setCountReq(nullObj.length);
      } else {
        setDataTable(filterData7);
        setCountReq(filterData7.length);
        setDataStatusFull(filterData7.length);

        // Set Data Status
        const filterdone = filterData7.filter(
          (item) => item.request_status.toLowerCase() === "done"
        );
        setDataDone(filterdone.length);

        const filterprocess = filterData7.filter(
          (item) => item.request_status.toLowerCase() === "processing"
        );
        setDataProcess(filterprocess.length);

        const filterwait = filterData7.filter(
          (item) => item.request_status.toLowerCase() === "waiting"
        );
        setDataWait(filterwait.length);

        const filtererror = filterData7.filter(
          (item) => item.request_status.toLowerCase() === "error"
        );
        setDataError(filtererror.length);

        const filtercancel = filterData7.filter(
          (item) => item.request_status.toLowerCase() === "cancelled"
        );
        setDataCancel(filtercancel.length);
      }
    } else if (filter === "Ebook to Audio") {
      const filterData8 = dataTableFull
        .filter((item) => item.request_type.toLowerCase() === "ebook to audio")
        .map((item, index) => ({
          ...item, // Menyalin semua properti dari item
          id: index + 1, // Update id mulai dari 1
        }));
      if (filterData8.length < 1) {
        setDataTable(nullObj);
        setCountReq(nullObj.length);
      } else {
        setDataTable(filterData8);
        setCountReq(filterData8.length);
        setDataStatusFull(filterData8.length);

        // Set Data Status
        const filterdone = filterData8.filter(
          (item) => item.request_status.toLowerCase() === "done"
        );
        setDataDone(filterdone.length);

        const filterprocess = filterData8.filter(
          (item) => item.request_status.toLowerCase() === "processing"
        );
        setDataProcess(filterprocess.length);

        const filterwait = filterData8.filter(
          (item) => item.request_status.toLowerCase() === "waiting"
        );
        setDataWait(filterwait.length);

        const filtererror = filterData8.filter(
          (item) => item.request_status.toLowerCase() === "error"
        );
        setDataError(filtererror.length);

        const filtercancel = filterData8.filter(
          (item) => item.request_status.toLowerCase() === "cancelled"
        );
        setDataCancel(filtercancel.length);
      }
    }

    // setFilter(e.value.split("(")[0].trim());
    setCurrentPage(1);
    // setRefresh(true);
  };

  const handleStatusChange = (e) => {
    console.log(e.value.split("(")[0].trim());
    const filter = e.value.split("(")[0].trim();

    const nullObj = [
      {
        id: "-",
        detail: "-",
        strdetail: "-",
        request_token: "-",
        request_date: "-",
        request_status: "-",
        request_type: "-",
        user: "-",
      },
    ];

    setValJenisStatus(e.value);

    if (filter === "Semua") {
      setDataTable(dataTableFull);
      setCountReq(dataTableFull.length);
    } else if (filter === "Done") {
      if (!valjenisTipe.includes("Semua")) {
        const filterData1 = dataTable
          .filter((item) => item.request_status.toLowerCase() === "done")
          .map((item, index) => ({
            ...item, // Menyalin semua properti dari item
            id: index + 1, // Update id mulai dari 1
          }));
        if (filterData1.length < 1) {
          setDataTable(nullObj);
          setCountReq(nullObj.length);
        } else {
          setDataTable(filterData1);
          setCountReq(filterData1.length);
        }
      } else {
        const filterData1 = dataTableFull
          .filter((item) => item.request_status.toLowerCase() === "done")
          .map((item, index) => ({
            ...item, // Menyalin semua properti dari item
            id: index + 1, // Update id mulai dari 1
          }));
        if (filterData1.length < 1) {
          setDataTable(nullObj);
          setCountReq(nullObj.length);
        } else {
          setDataTable(filterData1);
          setCountReq(filterData1.length);
        }
      }
    } else if (filter === "Processing") {
      if (!valjenisTipe.includes("Semua")) {
        const filterData2 = dataTable
          .filter((item) => item.request_status.toLowerCase() === "processing")
          .map((item, index) => ({
            ...item, // Menyalin semua properti dari item
            id: index + 1, // Update id mulai dari 1
          }));
        if (filterData2.length < 1) {
          setDataTable(nullObj);
          setCountReq(nullObj.length);
        } else {
          setDataTable(filterData2);
          setCountReq(filterData2.length);
        }
      } else {
        const filterData2 = dataTableFull
          .filter((item) => item.request_status.toLowerCase() === "processing")
          .map((item, index) => ({
            ...item, // Menyalin semua properti dari item
            id: index + 1, // Update id mulai dari 1
          }));
        if (filterData2.length < 1) {
          setDataTable(nullObj);
          setCountReq(nullObj.length);
        } else {
          setDataTable(filterData2);
          setCountReq(filterData2.length);
        }
      }
    } else if (filter === "Waiting") {
      if (!valjenisTipe.includes("Semua")) {
        const filterData3 = dataTable
          .filter((item) => item.request_status.toLowerCase() === "waiting")
          .map((item, index) => ({
            ...item, // Menyalin semua properti dari item
            id: index + 1, // Update id mulai dari 1
          }));
        if (filterData3.length < 1) {
          setDataTable(nullObj);
          setCountReq(nullObj.length);
        } else {
          setDataTable(filterData3);
          setCountReq(filterData3.length);
        }
      } else {
        const filterData3 = dataTableFull
          .filter((item) => item.request_status.toLowerCase() === "waiting")
          .map((item, index) => ({
            ...item, // Menyalin semua properti dari item
            id: index + 1, // Update id mulai dari 1
          }));
        if (filterData3.length < 1) {
          setDataTable(nullObj);
          setCountReq(nullObj.length);
        } else {
          setDataTable(filterData3);
          setCountReq(filterData3.length);
        }
      }
    } else if (filter === "Cancelled") {
      if (!valjenisTipe.includes("Semua")) {
        const filterData4 = dataTable
          .filter((item) => item.request_status.toLowerCase() === "cancelled")
          .map((item, index) => ({
            ...item, // Menyalin semua properti dari item
            id: index + 1, // Update id mulai dari 1
          }));
        if (filterData4.length < 1) {
          setDataTable(nullObj);
          setCountReq(nullObj.length);
        } else {
          setDataTable(filterData4);
          setCountReq(filterData4.length);
        }
      } else {
        const filterData4 = dataTableFull
          .filter((item) => item.request_status.toLowerCase() === "cancelled")
          .map((item, index) => ({
            ...item, // Menyalin semua properti dari item
            id: index + 1, // Update id mulai dari 1
          }));
        if (filterData4.length < 1) {
          setDataTable(nullObj);
          setCountReq(nullObj.length);
        } else {
          setDataTable(filterData4);
          setCountReq(filterData4.length);
        }
      }
    } else if (filter === "Error") {
      if (!valjenisTipe.includes("Semua")) {
        const filterData5 = dataTable
          .filter((item) => item.request_status.toLowerCase() === "error")
          .map((item, index) => ({
            ...item, // Menyalin semua properti dari item
            id: index + 1, // Update id mulai dari 1
          }));
        if (filterData5.length < 1) {
          setDataTable(nullObj);
          setCountReq(nullObj.length);
        } else {
          setDataTable(filterData5);
          setCountReq(filterData5.length);
        }
      } else {
        const filterData5 = dataTableFull
          .filter((item) => item.request_status.toLowerCase() === "error")
          .map((item, index) => ({
            ...item, // Menyalin semua properti dari item
            id: index + 1, // Update id mulai dari 1
          }));
        if (filterData5.length < 1) {
          setDataTable(nullObj);
          setCountReq(nullObj.length);
        } else {
          setDataTable(filterData5);
          setCountReq(filterData5.length);
        }
      }
    }

    // setFilter(e.value.split("(")[0].trim());
    setCurrentPage(1);
    // setRefresh(true);
  };

  const refreshRequest = (e) => {
    e.preventDefault();
    setValJenisTipe("Semua");
    setCurrentPage(1);
    setRefresh(true);
  };

  const showModule = (token, status, type, detail) => {
    if (type === "Review Modul" && status !== "cancelled") {
      props.tokenReview(token);
      props.statusReview(status);
      props.reqShow(true);
      props.typeShow("review");

      navigate("/dashboard");
      //getDownload(token, "review");
    } else if (type === "Generate Modul" && status !== "cancelled") {
      props.tokenGenerate(token);
      props.statusGenerate(status);
      props.reqShow(true);
      props.typeShow("generate");

      navigate("/dashboard");
      //getDownload(token, "generate");
    } else if (type === "Mapping Ebook" && status !== "cancelled") {
      props.tokenMapping(token);
      props.statusMapping(status);
      props.detailMapping(detail.file);
      navigate("/mapping");
    } else if (type === "Mapping Module" && status !== "cancelled") {
      props.tokenMapping(token);
      props.statusMapping(status);
      props.detailMapping(detail.file);
      navigate("/mapping");
    } else if (type === "Ebook to Audio" && status !== "cancelled") {
      props.tokenAudio(token);
      props.statusAudio(status);
      props.detailAudio(detail.file);
      navigate("/audiolearning");
    } else if (type === "Module to Audio" && status !== "cancelled") {
      props.tokenAudio(token);
      props.statusAudio(status);
      props.detailAudio(detail.file);
      navigate("/audiolearning");
    } else if (type === "Generate CoQa" && status !== "cancelled") {
      props.tokenCoqa(token);
      props.statusCoqa(status);
      props.detailCoqa(detail);
      navigate("/coqa");
    } else if (status === "cancelled") {
      alert("Status Cancelled tidak bisa di Show");
    }
  };

  const [judulEbook, setJudulEbook] = useState("");
  const [jenisEbook, setJenisEbook] = useState("");

  const [download, setDownload] = useState("");

  const editEbookClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setShowPopup(!showPopup);
    setShowEdit(!showEdit);
  };

  // Set Download Spinner
  const [isLoading, setIsLoading] = useState(false);

  const clickDownload = (e) => {
    setIsLoading(true);
    
    setTimeout(() => {
      // Setelah beberapa waktu, sembunyikan spinner dan tampilkan kembali tombol
      // const link = document.createElement("a");
      // link.href = download;
      // document.body.appendChild(link);
      // link.click();
      // link.remove();
      
      window.open(download, "_blank");
      setIsLoading(false);
    }, 1000); // Ganti dengan waktu proses Anda

  };

  const showEbook = (e, token) => {
    e.preventDefault();
    e.stopPropagation();

    setShowPopup(!showPopup);

    // console.log("INI TOKEN EBOOK : ", token);

    const fetchData = async () => {
      const formData = new FormData();
      formData.append("request_token", token);

      // console.log("Token Mapping : ", tokenCoqa);
      try {
        const res = await axios.post(
          process.env.REACT_APP_API_URL + "/result-vector-ebook/",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            timeout: 360000, // Timeout diatur ke 0 untuk menunggu sampai selesai
          }
        );
        let data = res.data.result;
        let download = data.link_download;
        console.log("Hasil : ", data);

        setJudulEbook(data.judul_ebook);
        setJenisEbook(data.jenis_ebook);

        if (download.length < 1) {
          // Jika data 0, ulangi request setelah 500 milis
          setTimeout(fetchData, 500);
        } else {
          // Set count request jika data bukan 0
          setDownload(download);
        }
      } catch (error) {
        console.error("Error saat memanggil API:", error);
      }
    };

    fetchData();
  };

  ////////////////////////////////////////////////////////////////////////

  // Delay Awal
  // const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setSpinner(true);
    const timer = setTimeout(() => {
      setSpinner(false);
    }, 1000); // 1 detik

    // Bersihkan timer jika komponen di-unmount
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    props.reset();
  }, []);

  const [spinner, setSpinner] = useState(false);
  const [tableReq, setTableReq] = useState(false);

  // SIMPAN DATA STATUS ORANG YANG SEDANG PROCESSING
  const [prosesUser, setProsesUser] = useState();

  // Count Request
  const [countReq, setCountReq] = useState(0);

  const fetchCountRequest = (dt) => {
    const formData = new FormData();
    if (
      props.user.nama === "Telkom Corpu" ||
      props.user.nama === "Agung S" ||
      props.user.nama === "Yosef G" ||
      props.user.nama === "Adi K" ||
      props.user.nama === "Jordan" ||
      props.user.nama === "Fadhil" ||
      props.user.nama === "Tito N" ||
      props.user.nama === "Gery P" ||
      props.user.nama === "andri"
    ) {
      formData.append("id_user", 0);
    } else {
      formData.append("id_user", props.user.id_user);
    }

    const tableStore = dt;

    try {
      if (tableStore.length !== 0) {
        axios({
          timeout: 360000,
          method: "post",
          url: process.env.REACT_APP_API_URL + "/count-request/",
          headers: { "Content-Type": "multipart/form-data" },
          data: formData,
        })
          .then(function (res) {
            let data = res.data.num_request;
            console.log("Count Req : ", data);

            if (data === 0) {
              // Jika data 0, ulangi request setelah 500 milis
              console.log("Ulangi Panggil Count, karena null");
              setTimeout(fetchCountRequest, 500);
            } else {
              // Set count request jika data bukan 0
              setCountReq(data);
            }

            console.log("Ambil Table Request Halaman ke : ", currentPage);
          })
          .catch(function (res) {
            console.log(res);
            return "";
          });
      } else {
        console.log("Belum Ada Request");
      }
    } catch (exception) {
      console.log(exception);
    }
  };

  // useEffect(() => {
  //   console.log("HALOO TEST");
  //   const formData = new FormData();
  //   formData.append("n_row", 8);
  //   formData.append("page", currentPage);
  //   if (
  //     props.user.nama === "Telkom Corpu" ||
  //     props.user.nama === "Agung S" ||
  //     props.user.nama === "Yosef G" ||
  //     props.user.nama === "Adi K" ||
  //     props.user.nama === "Jordan" ||
  //     props.user.nama === "Fadhil" ||
  //     props.user.nama === "Tito N" ||
  //     props.user.nama === "Gery P" ||
  //     props.user.nama === "andri"
  //   ) {
  //     formData.append("id_user", 0);
  //   } else {
  //     formData.append("id_user", props.user.id_user);
  //   }

  //   let arrayStore = [];
  //   let attempts = 0;
  //   let maxAttempts = 5;

  //   const getListEbook = async () => {
  //     while (arrayStore.length === 0 && attempts < maxAttempts) {
  //       try {
  //         const response = await axios.post(
  //           process.env.REACT_APP_API_URL + "/list-request/",
  //           formData,
  //           {
  //             headers: {
  //               "Content-Type": "multipart/form-data",
  //             },
  //           }
  //         );
  //         if (
  //           response.data.list_request &&
  //           response.data.list_request.length > 0
  //         ) {
  //           let data = response.data.list_request;
  //           arrayStore = data;

  //           const newData = data.map((item, index) => ({
  //             id: index + ((currentPage - 1) * 8 + 1), // Incremental id starting from 1
  //             detail: item.detail,
  //             strdetail: JSON.stringify(item.detail),
  //             request_token: item.request_token,
  //             request_date: item.request_date,
  //             request_status: item.request_status,
  //             request_type: item.request_type,
  //             user: item.user,
  //           }));

  //           fetchCountRequest(newData);

  //           // Jika tidak ada, simpan data ke state
  //           console.log("Test", newData);
  //           if (newData.length < 1) {
  //             console.log("Refresh");
  //             setRefresh(true);
  //           }

  //           // Filter array to include only users with user = props.user.nama
  //           if (
  //             props.user.nama === "Telkom Corpu" ||
  //             props.user.nama === "Agung S" ||
  //             props.user.nama === "Yosef G" ||
  //             props.user.nama === "Adi K" ||
  //             props.user.nama === "Jordan" ||
  //             props.user.nama === "Fadhil" ||
  //             props.user.nama === "Tito N" ||
  //             props.user.nama === "Gery P" ||
  //             props.user.nama === "andri"
  //           ) {
  //             setStatusKlikKanan(true);
  //             const updatedObjData = newData.map((obj) => {
  //               if (obj.request_type === "review module") {
  //                 return { ...obj, request_type: "Review Modul" };
  //               } else if (obj.request_type === "generate module") {
  //                 return { ...obj, request_type: "Generate Modul" };
  //               } else if (obj.request_type === "extract ebook") {
  //                 return { ...obj, request_type: "Extract Ebook" };
  //               } else if (obj.request_type === "mapping ebook") {
  //                 return { ...obj, request_type: "Mapping Ebook" };
  //               } else if (obj.request_type === "mapping module") {
  //                 return { ...obj, request_type: "Mapping Module" };
  //               } else if (obj.request_type === "module to audio") {
  //                 return { ...obj, request_type: "Module to Audio" };
  //               } else if (obj.request_type === "ebook to audio") {
  //                 return { ...obj, request_type: "Ebook to Audio" };
  //               } else if (obj.request_type === "generate coqa") {
  //                 return { ...obj, request_type: "Generate CoQa" };
  //               }
  //               return obj;
  //             });

  //             setDataTable(updatedObjData);
  //           } else {
  //             setStatusKlikKanan(false);
  //             const filterData = newData.filter(
  //               (item) => item.user === props.user.nama
  //             );

  //             const updatedObjData = filterData.map((obj) => {
  //               if (obj.request_type === "review module") {
  //                 return { ...obj, request_type: "Review Modul" };
  //               } else if (obj.request_type === "generate module") {
  //                 return { ...obj, request_type: "Generate Modul" };
  //               } else if (obj.request_type === "extract ebook") {
  //                 return { ...obj, request_type: "Extract Ebook" };
  //               } else if (obj.request_type === "mapping ebook") {
  //                 return { ...obj, request_type: "Mapping Ebook" };
  //               } else if (obj.request_type === "mapping module") {
  //                 return { ...obj, request_type: "Mapping Module" };
  //               } else if (obj.request_type === "module to audio") {
  //                 return { ...obj, request_type: "Module to Audio" };
  //               } else if (obj.request_type === "ebook to audio") {
  //                 return { ...obj, request_type: "Ebook to Audio" };
  //               } else if (obj.request_type === "generate coqa") {
  //                 return { ...obj, request_type: "Generate CoQa" };
  //               }
  //               return obj;
  //             });

  //             setDataTable(updatedObjData);
  //           }

  //           console.log("hayy:", data);

  //           setSpinner(true);
  //           setTableReq(false);

  //           setTimeout(() => {
  //             setSpinner(false);
  //             setTableReq(true);

  //             const filterProses = newData
  //               .filter((item) => item.request_status === "processing")
  //               .map((item, index) => ({ ...item, id: index + 1 }));

  //             const hasil = filterProses.map((obj) => {
  //               return obj;
  //             });

  //             console.log(hasil);

  //             if (hasil.length > 0) {
  //               setProsesUser(hasil[0].user);
  //             } else {
  //               setProsesUser("");
  //             }
  //           }, 1000);

  //           break; // Hentikan loop jika token berhasil diambil
  //         } else {
  //           console.log("Data kosong, mencoba lagi...");
  //         }
  //       } catch (error) {
  //         console.error("Error fetching data:", error);
  //       }

  //       attempts += 1;
  //     }

  //     if (arrayStore.length === 0) {
  //       console.log(`Gagal mendapatkan data setelah ${maxAttempts} percobaan.`);
  //     }
  //   };

  //   getListEbook();
  // }, [refresh]);

  // AMBIL SELURUH DATA
  useEffect(() => {
    const formData = new FormData();
    formData.append("n_row", 9999);
    formData.append("page", 1);
    if (
      props.user.nama === "Telkom Corpu" ||
      props.user.nama === "Agung S" ||
      props.user.nama === "Yosef G" ||
      props.user.nama === "Adi K" ||
      props.user.nama === "Jordan" ||
      props.user.nama === "Fadhil" ||
      props.user.nama === "Tito N" ||
      props.user.nama === "Gery P" ||
      props.user.nama === "andri"
    ) {
      formData.append("id_user", 0);
    } else {
      formData.append("id_user", props.user.id_user);
    }

    let arrayStore = [];
    let attempts = 0;
    let maxAttempts = 5;

    const getListReq = async () => {
      while (arrayStore.length === 0 && attempts < maxAttempts) {
        try {
          const response = await axios.post(
            process.env.REACT_APP_API_URL + "/list-request/",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          if (
            response.data.list_request &&
            response.data.list_request.length > 0
          ) {
            let data = response.data.list_request;
            arrayStore = data;

            const newData = data.map((item, index) => ({
              id: index + ((currentPage - 1) * 8 + 1), // Incremental id starting from 1
              detail: item.detail,
              strdetail: JSON.stringify(item.detail),
              request_token: item.request_token,
              request_date: item.request_date,
              request_status: item.request_status,
              request_type: item.request_type,
              user: item.user,
            }));

            // fetchCountRequest(newData);
            setCountReq(newData.length);

            // Jika tidak ada, simpan data ke state
            console.log("Seluruh Data (Newdata) : ", newData);
            if (newData.length < 1) {
              console.log("Refresh");
              setRefresh(true);
            }

            // Filter array to include only users with user = props.user.nama
            if (
              props.user.nama === "Telkom Corpu" ||
              props.user.nama === "Agung S" ||
              props.user.nama === "Yosef G" ||
              props.user.nama === "Adi K" ||
              props.user.nama === "Jordan" ||
              props.user.nama === "Fadhil" ||
              props.user.nama === "Tito N" ||
              props.user.nama === "Gery P" ||
              props.user.nama === "andri"
            ) {
              setStatusKlikKanan(true);
              const updatedObjData = newData.map((obj) => {
                if (obj.request_type === "review module") {
                  return { ...obj, request_type: "Review Modul" };
                } else if (obj.request_type === "generate module") {
                  return { ...obj, request_type: "Generate Modul" };
                } else if (obj.request_type === "extract ebook") {
                  return { ...obj, request_type: "Extract Ebook" };
                } else if (obj.request_type === "mapping ebook") {
                  return { ...obj, request_type: "Mapping Ebook" };
                } else if (obj.request_type === "mapping module") {
                  return { ...obj, request_type: "Mapping Module" };
                } else if (obj.request_type === "module to audio") {
                  return { ...obj, request_type: "Module to Audio" };
                } else if (obj.request_type === "ebook to audio") {
                  return { ...obj, request_type: "Ebook to Audio" };
                } else if (obj.request_type === "generate coqa") {
                  return { ...obj, request_type: "Generate CoQa" };
                }
                return obj;
              });

              setDataTable(updatedObjData);
              setDataTableFull(updatedObjData);

              setDataTipeFull(updatedObjData.length);
              setDataStatusFull(updatedObjData.length);

              const filterData1 = updatedObjData.filter(
                (item) => item.request_type.toLowerCase() === "review modul"
              );
              setDataReview(filterData1.length);

              const filterData2 = updatedObjData.filter(
                (item) => item.request_type.toLowerCase() === "generate modul"
              );
              setDataGenerate(filterData2.length);

              const filterData3 = updatedObjData.filter(
                (item) => item.request_type.toLowerCase() === "extract ebook"
              );
              setDataEbook(filterData3.length);

              //////////////////////////////////////////////////////////

              const filterData4 = updatedObjData.filter(
                (item) => item.request_type.toLowerCase() === "generate coqa"
              );
              setDataCoqa(filterData4.length);

              const filterData5 = updatedObjData.filter(
                (item) => item.request_type.toLowerCase() === "mapping module"
              );
              setDataMappingM(filterData5.length);

              const filterData6 = updatedObjData.filter(
                (item) => item.request_type.toLowerCase() === "mapping ebook"
              );
              setDataMappingE(filterData6.length);

              const filterData7 = updatedObjData.filter(
                (item) => item.request_type.toLowerCase() === "module to audio"
              );
              setDataAudioM(filterData7.length);

              const filterData8 = updatedObjData.filter(
                (item) => item.request_type.toLowerCase() === "ebook to audio"
              );
              setDataAudioE(filterData8.length);

              //////////////////////////////////////////////////////////////////
              const filterData9 = updatedObjData.filter(
                (item) => item.request_status.toLowerCase() === "done"
              );
              setDataDone(filterData9.length);

              const filterData10 = updatedObjData.filter(
                (item) => item.request_status.toLowerCase() === "processing"
              );
              setDataProcess(filterData10.length);

              const filterData11 = updatedObjData.filter(
                (item) => item.request_status.toLowerCase() === "waiting"
              );
              setDataWait(filterData11.length);

              const filterData13 = updatedObjData.filter(
                (item) => item.request_status.toLowerCase() === "error"
              );
              setDataError(filterData13.length);

              const filterData12 = updatedObjData.filter(
                (item) => item.request_status.toLowerCase() === "cancelled"
              );
              setDataCancel(filterData12.length);
            } else {
              setStatusKlikKanan(false);
              const filterData = newData.filter(
                (item) => item.user === props.user.nama
              );

              const updatedObjData = filterData.map((obj) => {
                if (obj.request_type === "review module") {
                  return { ...obj, request_type: "Review Modul" };
                } else if (obj.request_type === "generate module") {
                  return { ...obj, request_type: "Generate Modul" };
                } else if (obj.request_type === "extract ebook") {
                  return { ...obj, request_type: "Extract Ebook" };
                } else if (obj.request_type === "mapping ebook") {
                  return { ...obj, request_type: "Mapping Ebook" };
                } else if (obj.request_type === "mapping module") {
                  return { ...obj, request_type: "Mapping Module" };
                } else if (obj.request_type === "module to audio") {
                  return { ...obj, request_type: "Module to Audio" };
                } else if (obj.request_type === "ebook to audio") {
                  return { ...obj, request_type: "Ebook to Audio" };
                } else if (obj.request_type === "generate coqa") {
                  return { ...obj, request_type: "Generate CoQa" };
                }
                return obj;
              });

              setDataTable(updatedObjData);
              setDataTableFull(updatedObjData);

              setDataTipeFull(updatedObjData.length);
              setDataStatusFull(updatedObjData.length);

              const filterData1 = updatedObjData.filter(
                (item) => item.request_type.toLowerCase() === "review modul"
              );
              setDataReview(filterData1.length);

              const filterData2 = updatedObjData.filter(
                (item) => item.request_type.toLowerCase() === "generate modul"
              );
              setDataGenerate(filterData2.length);

              const filterData3 = updatedObjData.filter(
                (item) => item.request_type.toLowerCase() === "extract ebook"
              );
              setDataEbook(filterData3.length);

              //////////////////////////////////////////////////////////

              const filterData4 = updatedObjData.filter(
                (item) => item.request_type.toLowerCase() === "generate coqa"
              );
              setDataCoqa(filterData4.length);

              const filterData5 = updatedObjData.filter(
                (item) => item.request_type.toLowerCase() === "mapping module"
              );
              setDataMappingM(filterData5.length);

              const filterData6 = updatedObjData.filter(
                (item) => item.request_type.toLowerCase() === "mapping ebook"
              );
              setDataMappingE(filterData6.length);

              const filterData7 = updatedObjData.filter(
                (item) => item.request_type.toLowerCase() === "module to audio"
              );
              setDataAudioM(filterData7.length);

              const filterData8 = updatedObjData.filter(
                (item) => item.request_type.toLowerCase() === "ebook to audio"
              );
              setDataAudioE(filterData8.length);

              //////////////////////////////////////////////////////////////////

              const filterData9 = updatedObjData.filter(
                (item) => item.request_status.toLowerCase() === "done"
              );
              setDataDone(filterData9.length);

              const filterData10 = updatedObjData.filter(
                (item) => item.request_status.toLowerCase() === "processing"
              );
              setDataProcess(filterData10.length);

              const filterData11 = updatedObjData.filter(
                (item) => item.request_status.toLowerCase() === "waiting"
              );
              setDataWait(filterData11.length);

              const filterData13 = updatedObjData.filter(
                (item) => item.request_status.toLowerCase() === "error"
              );
              setDataError(filterData13.length);

              const filterData12 = updatedObjData.filter(
                (item) => item.request_status.toLowerCase() === "cancelled"
              );
              setDataCancel(filterData12.length);
            }

            console.log("Full :", data);

            setSpinner(true);
            setTableReq(false);

            setTimeout(() => {
              setSpinner(false);
              setTableReq(true);
            }, 1000);

            break; // Hentikan loop jika token berhasil diambil
          } else {
            console.log("Data kosong, mencoba lagi...");
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }

        attempts += 1;
      }

      if (arrayStore.length === 0) {
        console.log(`Gagal mendapatkan data setelah ${maxAttempts} percobaan.`);
      }
    };

    getListReq();
  }, [refresh]);

  // useEffect(() => {
  //   // Ambil Count Request
  //   const formData = new FormData();
  //   formData.append("id_user", props.user.id_user);

  //   axios({
  //     timeout: 360000,
  //     method: "post",
  //     url: process.env.REACT_APP_API_URL + "/count-request/",
  //     headers: { "Content-Type": "multipart/form-data" },
  //     data: formData,
  //   })
  //     .then(function (res) {
  //       let data = res.data.num_request;
  //       console.log("Count Req : ", data);

  //       setCountReq(data);

  //       console.log("Ambil Table Request", currentPage);
  //     })
  //     .catch(function (res) {
  //       console.log(res);
  //       return "";
  //     });
  // }, [refresh]);

  // useEffect(() => {
  //   // Fungsi untuk mengambil count request
  //   const fetchCountRequest = () => {
  //     const formData = new FormData();
  //     if (
  //       props.user.nama === "Telkom Corpu" ||
  //       props.user.nama === "Agung S" ||
  //       props.user.nama === "Yosef G" ||
  //       props.user.nama === "Adi K" ||
  //       props.user.nama === "Jordan" ||
  //       props.user.nama === "Fadhil" ||
  //       props.user.nama === "Tito N" ||
  //       props.user.nama === "Gery P" ||
  //       props.user.nama === "andri"
  //     ) {
  //       formData.append("id_user", 0);
  //     } else {
  //       formData.append("id_user", props.user.id_user);
  //     }

  //     if (dataTable.length !== 0) {
  //       axios({
  //         timeout: 360000,
  //         method: "post",
  //         url: process.env.REACT_APP_API_URL + "/count-request/",
  //         headers: { "Content-Type": "multipart/form-data" },
  //         data: formData,
  //       })
  //         .then(function (res) {
  //           let data = res.data.num_request;
  //           console.log("Count Req : ", data);

  //           if (data === 0) {
  //             // Jika data 0, ulangi request setelah 500 milis
  //             console.log("Ulangi Panggil Count, karena null");
  //             setTimeout(fetchCountRequest, 500);
  //           } else {
  //             // Set count request jika data bukan 0
  //             setCountReq(data);
  //           }

  //           console.log("Ambil Table Request Halaman ke : ", currentPage);
  //         })
  //         .catch(function (res) {
  //           console.log(res);
  //           return "";
  //         });
  //     } else {
  //       console.log("Belum Ada Request");
  //     }
  //   };

  //   // Panggil fungsi pertama kali
  //   fetchCountRequest();
  // }, [refresh]);

  useEffect(() => {
    setRefresh(false);
  }, [refresh]);

  // useEffect(() => {
  //   console.log("JALANNNNNNNNN");
  //   const formData = new FormData();
  //   formData.append("n_row", 8);
  //   formData.append("page", currentPage);
  //   console.log(props.user.nama + props.user.id_user);
  //   if (
  //     props.user.nama === "Telkom Corpu" ||
  //     props.user.nama === "Agung S" ||
  //     props.user.nama === "Yosef G" ||
  //     props.user.nama === "Adi K" ||
  //     props.user.nama === "Jordan" ||
  //     props.user.nama === "Fadhil" ||
  //     props.user.nama === "Tito N" ||
  //     props.user.nama === "Gery P" ||
  //     props.user.nama === "andri"
  //   ) {
  //     formData.append("id_user", 0);
  //   } else {
  //     formData.append("id_user", props.user.id_user);
  //   }

  //   if(cariStatus == true) {
  //     //
  //   } else {
  //     axios({
  //       timeout: 360000,
  //       method: "post",
  //       url: process.env.REACT_APP_API_URL + "/list-request/",
  //       data: formData,
  //       headers: { "Content-Type": "multipart/form-data" },
  //     })
  //       .then(function (res) {
  //         let data = res.data.list_request;

  //         const newData = data.map((item, index) => ({
  //           id: index + ((currentPage - 1) * 8 + 1), // Incremental id starting from 1
  //           detail: item.detail,
  //           strdetail: JSON.stringify(item.detail),
  //           request_token: item.request_token,
  //           request_date: item.request_date,
  //           request_status: item.request_status,
  //           request_type: item.request_type,
  //           user: item.user,
  //         }));

  //         console.log("Test Next Page", newData);
  //         if (newData.length < 1) {
  //           console.log("Refresh");
  //           setRefresh(true);
  //         }

  //         // Filter array to include only users with user = props.user.nama
  //         if (
  //           props.user.nama === "Telkom Corpu" ||
  //           props.user.nama === "Agung S" ||
  //           props.user.nama === "Yosef G" ||
  //           props.user.nama === "Adi K" ||
  //           props.user.nama === "Jordan" ||
  //           props.user.nama === "Fadhil" ||
  //           props.user.nama === "Tito N" ||
  //           props.user.nama === "Gery P" ||
  //           props.user.nama === "andri"
  //         ) {
  //           setStatusKlikKanan(true);
  //           const updatedObjData = newData.map((obj) => {
  //             if (obj.request_type === "review module") {
  //               return { ...obj, request_type: "Review Modul" };
  //             } else if (obj.request_type === "generate module") {
  //               return { ...obj, request_type: "Generate Modul" };
  //             } else if (obj.request_type === "extract ebook") {
  //               return { ...obj, request_type: "Extract Ebook" };
  //             } else if (obj.request_type === "mapping ebook") {
  //               return { ...obj, request_type: "Mapping Ebook" };
  //             } else if (obj.request_type === "mapping module") {
  //               return { ...obj, request_type: "Mapping Module" };
  //             } else if (obj.request_type === "module to audio") {
  //               return { ...obj, request_type: "Module to Audio" };
  //             } else if (obj.request_type === "ebook to audio") {
  //               return { ...obj, request_type: "Ebook to Audio" };
  //             } else if (obj.request_type === "generate coqa") {
  //               return { ...obj, request_type: "Generate CoQa" };
  //             }
  //             return obj;
  //           });

  //           setDataTable(updatedObjData);
  //         } else {
  //           setStatusKlikKanan(false);
  //           const filterData = newData.filter(
  //             (item) => item.user === props.user.nama
  //           );

  //           const updatedObjData = filterData.map((obj) => {
  //             if (obj.request_type === "review module") {
  //               return { ...obj, request_type: "Review Modul" };
  //             } else if (obj.request_type === "generate module") {
  //               return { ...obj, request_type: "Generate Modul" };
  //             } else if (obj.request_type === "extract ebook") {
  //               return { ...obj, request_type: "Extract Ebook" };
  //             } else if (obj.request_type === "mapping ebook") {
  //               return { ...obj, request_type: "Mapping Ebook" };
  //             } else if (obj.request_type === "mapping module") {
  //               return { ...obj, request_type: "Mapping Module" };
  //             } else if (obj.request_type === "module to audio") {
  //               return { ...obj, request_type: "Module to Audio" };
  //             } else if (obj.request_type === "ebook to audio") {
  //               return { ...obj, request_type: "Ebook to Audio" };
  //             } else if (obj.request_type === "generate coqa") {
  //               return { ...obj, request_type: "Generate CoQa" };
  //             }
  //             return obj;
  //           });

  //           setDataTable(updatedObjData);
  //         }
  //       })
  //       .catch(function (res) {
  //         console.log(res);
  //         return "";
  //       });
  //   }

  // }, [currentPage]);

  //////////////////////////////////////////////////////////////////////////////
  // KLIK KANAN LOGIC

  const [contextMenu, setContextMenu] = useState(null);
  const contextMenuRef = useRef();
  const [statusKlikKanan, setStatusKlikKanan] = useState(false);

  const handleRightClick = (e, row) => {
    e.preventDefault();
    setCurrentPage(currentPage);
    console.log(row.user);
    setContextMenu({
      x: e.clientX - 65,
      y: e.clientY - 5,
      token: row.request_token,
      status: row.request_status,
      type: row.request_type,
      user: row.user,
    });
  };

  const handleClickOutside = (event) => {
    if (
      contextMenuRef.current &&
      !contextMenuRef.current.contains(event.target)
    ) {
      setContextMenu(null);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const klikKananShow = (e) => {
    e.preventDefault();
    // console.log(contextMenu.status);
    if (contextMenu.status === "cancelled") {
      alert("Status Cancelled tidak bisa di Show");
    } else {
      showModule(contextMenu.token, contextMenu.status, contextMenu.type);
    }
    setContextMenu(null);
  };

  const klikKananCancel = (e) => {
    e.preventDefault();
    // console.log(contextMenu.status);

    if (contextMenu.status === "waiting") {
      const formData = new FormData();
      formData.append("request_token", contextMenu.token);

      axios({
        timeout: 360000,
        method: "post",
        url: process.env.REACT_APP_API_URL + "/cancel-request/",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (res) {
          let hasil = res.data.status;
          alert("Status Cancel : " + hasil);

          setTimeout(() => {
            setRefresh(true);
          }, 1000);
        })
        .catch(function (res) {
          console.log(res);
          return "";
        });
    } else {
      alert("Hanya Proses Waiting yang bisa di Cancel");
    }

    setContextMenu(null);
  };

  const showToken = (e, token) => {
    e.preventDefault();
    console.log(token);
  };

  const showDetail = (e, detail) => {
    e.preventDefault();
    console.log(detail);
  };

  // Help Popup
  const [helpDetail, setHelpDetail] = useState("");
  const [helpType, setHelpType] = useState("");

  const onClickHelp = (e, detail, type) => {
    e.preventDefault();
    console.log(detail);

    if (type === "Review Modul") {
      const newData = { filename: detail.filename };
      setHelpDetail(newData);
    } else if (type === "Generate Modul") {
      const newData = {
        kompetensi: detail.filename,
        judul_modul: detail.judul_modul,
        elo: detail.elo,
        topik_1: detail.topik_1,
        topik_2: detail.topik_2,
        topik_3: detail.topik_3,
        reference_1: detail.reference_1,
        reference_2: detail.reference_2,
        reference_3: detail.reference_3,
        reference_4: detail.reference_4,
        reference_5: detail.reference_5,
      };
      setHelpDetail(newData);
    } else if (type === "Extract Ebook") {
      const newData = {
        judul_ebook: detail.judul_ebook,
        jenis_ebook: detail.jenis_ebook,
        author: detail.author,
        bahasa: detail.bahasa,
        penerbit: detail.penerbit,
        tahun_terbit: detail.tahun_terbit,
        isbn: detail.isbn,
        doi: detail.doi,
        ebook: detail.filepath.substring(detail.filepath.lastIndexOf("/") + 1),
      };
      setHelpDetail(newData);
    } else if (type === "Mapping Ebook") {
      const newData = { filename: detail.file };
      setHelpDetail(newData);
    } else if (type === "Mapping Module") {
      const newData = { filename: detail.file };
      setHelpDetail(newData);
    } else if (type === "Ebook to Audio") {
      const newData = { filename: detail.file };
      setHelpDetail(newData);
    } else if (type === "Module to Audio") {
      const newData = { filename: detail.file };
      setHelpDetail(newData);
    } else if (type === "Generate CoQa") {
      const newData = {
        jumlah_soal: detail.jumlah_soal,
        level:
          detail.level.charAt(0).toUpperCase() +
          detail.level.slice(1).toLowerCase(),
        kompetensi: detail.nama_kompetensi,
        reference: detail.reference,
      };
      setHelpDetail(newData);
    }

    setHelpType(type);

    setHelpPopup(!helpPopup);
  };

  /////////////////////////////////////////////////////////////////////////////

  const TableWithPagination = () => {
    // Data yang akan ditampilkan di halaman saat ini
    // const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 8;

    // Hitung jumlah total halaman
    const totalPages = Math.ceil(countReq / itemsPerPage);
    // const totalPages = Math.ceil(countReq / itemsPerPage);
    const [banyakPages, setBanyakPages] = useState([]);

    const currentData = dataTable.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage
    );

    const handlePageChange = (page) => {
      setCurrentPage(page);
    };

    return (
      <div>
        {spinner && (
          <div className="spinner_req">
            <Spinner />
          </div>
        )}

        {tableReq && (
          <div>
            {/* {!prosesUser ? null : (
              <div style={{ marginBottom: "10px", fontSize: "14px" }}>
                <span style={{ color: "red" }}>[ {prosesUser} ]</span>
                <span>
                  &nbsp;sedang melakukan Proses, harap tunggu antrean..
                </span>
              </div>
            )} */}

            <div className="item_jenisReq">
              <div>
                <span className="span-items_listebooks">Jenis Tipe</span>
                <Dropdown
                  className="dropDown-jenisEbook"
                  options={options_jenisTipe}
                  value={valjenisTipe}
                  onChange={(e) => handleJenisChange(e)}
                />
              </div>

              <div style={{ marginLeft: "10px" }}>
                <span className="span-items_listebooks">Status</span>
                <Dropdown
                  className="dropDown-jenisEbook"
                  options={options_jenisStatus}
                  value={valjenisStatus}
                  onChange={(e) => handleStatusChange(e)}
                />
              </div>
            </div>

            <table className="table_requests" border="1">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Request Date</th>
                  <th>Type</th>
                  <th>Details</th>
                  <th>Status</th>
                  <th>User</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {currentData.map((item) => (
                  <tr
                    key={item.id}
                    onContextMenu={(e) => handleRightClick(e, item)}
                  >
                    <td>{item.id}</td>
                    <td
                      style={{ width: "300px" }}
                      onClick={(e) => showDetail(e, item.detail)}
                    >
                      {item.request_date}
                    </td>
                    <td
                      style={{ width: "300px", textAlign: "center" }}
                      onClick={(e) => showToken(e, item.request_token)}
                    >
                      {
                        <div>
                          <span>{item.request_type}</span>
                        </div>
                      }
                    </td>
                    <td
                      style={{
                        width: "300px",
                      }}
                      onClick={(e) =>
                        onClickHelp(e, item.detail, item.request_type)
                      }
                    >
                      {
                        <div className="helpDetail_content">
                          {item.request_type === "Review Modul" ? (
                            <div>
                              {item.detail.filename.length > 30 ? (
                                <div>
                                  {item.detail.filename.substring(0, 30)} ...
                                </div>
                              ) : (
                                <div>{item.detail.filename}</div>
                              )}
                            </div>
                          ) : item.request_type === "Generate Modul" ? (
                            <div>
                              {item.detail.filename.length > 30 ? (
                                <div>
                                  {item.detail.filename.substring(0, 30)} ...
                                </div>
                              ) : (
                                <div>{item.detail.filename}</div>
                              )}
                            </div>
                          ) : item.request_type === "Extract Ebook" ? (
                            <div>
                              {item.detail.judul_ebook.length > 30 ? (
                                <div>
                                  {item.detail.judul_ebook.substring(0, 30)} ...
                                </div>
                              ) : (
                                <div>{item.detail.judul_ebook}</div>
                              )}
                            </div>
                          ) : item.request_type === "Mapping Ebook" ? (
                            <div>
                              {item.detail.file.length > 30 ? (
                                <div>
                                  {item.detail.file.substring(0, 30)} ...
                                </div>
                              ) : (
                                <div>{item.detail.file}</div>
                              )}
                            </div>
                          ) : item.request_type === "Mapping Module" ? (
                            <div>
                              {item.detail.file.length > 30 ? (
                                <div>
                                  {item.detail.file.substring(0, 30)} ...
                                </div>
                              ) : (
                                <div>{item.detail.file}</div>
                              )}
                            </div>
                          ) : item.request_type === "Ebook to Audio" ? (
                            <div>
                              {item.detail.file.length > 30 ? (
                                <div>
                                  {item.detail.file.substring(0, 30)} ...
                                </div>
                              ) : (
                                <div>{item.detail.file}</div>
                              )}
                            </div>
                          ) : item.request_type === "Module to Audio" ? (
                            <div>
                              {item.detail.file.length > 30 ? (
                                <div>
                                  {item.detail.file.substring(0, 30)} ...
                                </div>
                              ) : (
                                <div>{item.detail.file}</div>
                              )}
                            </div>
                          ) : item.request_type === "Generate CoQa" ? (
                            <div>
                              {item.detail.nama_kompetensi.length > 30 ? (
                                <div>
                                  {item.detail.nama_kompetensi.substring(0, 30)}{" "}
                                  ...
                                </div>
                              ) : (
                                <div>{item.detail.nama_kompetensi}</div>
                              )}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      }

                      <Popup
                        handleClose={onClickHelp}
                        show={helpPopup}
                        judulContent={<h1>Details {helpType}</h1>}
                        isiContent={
                          <div style={{ marginTop: "10px", fontSize: "14px" }}>
                            <ul
                              style={{ listStyleType: "none", paddingLeft: 0 }}
                            >
                              {Object.entries(helpDetail).map(
                                ([key, value], index) => (
                                  <li
                                    key={index}
                                    style={{
                                      display: "flex",
                                      marginBottom: "8px",
                                      textAlign: "start",
                                    }}
                                  >
                                    <span
                                      style={{
                                        minWidth: "120px",
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {key.replace("_", " ")}
                                    </span>
                                    {value === null || value === "" ? (
                                      <span>: -</span>
                                    ) : (
                                      <span style={{ wordBreak: "break-all" }}>
                                        : {value}
                                      </span>
                                    )}
                                  </li>
                                )
                              )}
                            </ul>
                          </div>
                        }
                      />
                    </td>
                    <td
                      style={{
                        width: "250px",
                        textAlign: "center",
                        color:
                          item.request_status === "done"
                            ? "green"
                            : item.request_status === "error"
                            ? "red"
                            : item.request_status === "processing"
                            ? "blue"
                            : item.request_status === "waiting"
                            ? "orange"
                            : "black",
                      }}
                    >
                      {item.request_status}
                    </td>
                    <td style={{ width: "300px", textAlign: "center" }}>
                      {item.user}
                    </td>
                    <td>
                      {item.request_type === "Extract Ebook" ? (
                        <div>
                          <button
                            className="showBut-req"
                            onClick={(e) => showEbook(e, item.request_token)}
                          >
                            Show
                          </button>

                          <Popup
                            handleClose={showEbook}
                            show={showPopup}
                            judulContent={"Details Ebook"}
                            isiContent={
                              <div className="popUp_Ebook">
                                <p
                                  style={{
                                    fontSize: "16px",
                                    margin: "6px 0",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Judul Buku :
                                </p>
                                <p>{judulEbook}</p>
                                <p
                                  style={{
                                    fontSize: "16px",
                                    margin: "6px 0",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Jenis Buku :
                                </p>
                                <p>{jenisEbook}</p>
                                <div
                                  style={{
                                    display: "flex",
                                    width: "100%",
                                    justifyContent: "center",
                                  }}
                                >
                                  {/* <button
                                    className="edit_Ebook"
                                    onClick={editEbookClick}
                                  >
                                    Edit
                                  </button> */}
                                  {!isLoading ? (
                                    <button
                                      className="downloadReq_Ebook"
                                      onClick={clickDownload}
                                    >
                                      Download
                                    </button>
                                  ) : (
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <Spinner />
                                    </div>
                                  )}
                                </div>
                              </div>
                            }
                          />

                          <EditEbook
                            handleClose={editEbookClick}
                            show={showEdit}
                          />
                        </div>
                      ) : (
                        <div>
                          <button
                            className="showBut-req"
                            onClick={() =>
                              showModule(
                                item.request_token,
                                item.request_status,
                                item.request_type,
                                item.detail
                              )
                            }
                          >
                            Show
                          </button>
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div className="footer_requests">
              <div className="refresh_req">
                <button className="refreshBut" onClick={refreshRequest}>
                  Refresh Requests
                </button>
              </div>

              <div className="pagination_req">
                <div className="pagination_req">
                  <Pagination
                    totalPages={totalPages}
                    currentPage={currentPage}
                    onPageChange={handlePageChange}
                  />
                </div>
              </div>
            </div>

            {statusKlikKanan && (
              <div>
                {contextMenu && (
                  <div
                    ref={contextMenuRef}
                    className="context-menu"
                    style={{
                      top: `${contextMenu.y}px`,
                      left: `${contextMenu.x}px`,
                    }}
                  >
                    <div onClick={(e) => klikKananShow(e)}>Show Process</div>
                    <div onClick={(e) => klikKananCancel(e)}>
                      Cancel Process
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="container">
      <nav className="sidebar">
        <ul>
          <li>
            <div className="logoMarshall">
              <img src={logo} alt=""></img>
              <span className="nav-item">Marshall</span>
            </div>
          </li>
          <li>
            <Link to={"/dashboard"}>
              <IoCreateOutline className="fas" />
              <span className="nav-item">Create</span>
            </Link>
          </li>
          <li>
            <Link to={"/requests"}>
              <AiOutlineHistory className="fas" />
              <span className="nav-item">View Requests History</span>
            </Link>
          </li>
          {/* <li>
            <Link to={"/settings"}>
              <BsGear className="fas" />
              <span className="nav-item">Settings</span>
            </Link>
          </li> */}
          <li>
            <Link to={"/eLibrary"}>
              <LuBookDown className="fas" />
              <span className="nav-item">E-Library</span>
            </Link>
          </li>
          <li>
            <Link to={"/coqa"}>
              <LuFileQuestion className="fas" />
              <span className="nav-item">CoQa</span>
            </Link>
          </li>
          <li>
            <Link to={"/mapping"}>
              <MdManageSearch className="fas" />
              <span className="nav-item">Mapping Dirkom 11</span>
            </Link>
          </li>
          <li>
            <Link to={"/audiolearning"}>
              <MdSpatialAudio className="fas" />
              <span className="nav-item">Audio Learning</span>
            </Link>
          </li>
          {/* <li>
            <Link to={"/videolearning"}>
              <MdOutlineSlowMotionVideo className="fas" />
              <span className="nav-item">Video Learning</span>
            </Link>
          </li> */}
          <li>
            <Link to={"/contactus"}>
              <LuContact2 className="fas" />
              <span className="nav-item">Contact Us</span>
            </Link>
          </li>
          <li className="logout">
            <Link to={"/"}>
              <MdLogout className="fas" />
              <span className="nav-item-logout">Logout</span>
            </Link>
          </li>
        </ul>
      </nav>

      <section className="main">
        <div className="container_request">
          <h1 id="judul_request">Requests History</h1>
          {dataTable.length === 0 ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                padding: "10px",
              }}
            >
              {spinner ? (
                <Spinner />
              ) : (
                <span style={{ fontSize: "18px", fontWeight: "bold" }}>
                  BELUM ADA REQUEST HISTORY
                </span>
              )}
            </div>
          ) : (
            <TableWithPagination />
          )}
        </div>
      </section>
      <span
        style={{
          display: "flex",
          position: "absolute",
          bottom: "0",
          right: "0",
          padding: "5px",
        }}
      >
        ~ Beta Version 1.0
      </span>
    </div>
  );
};

export default Requests;
