import React, { useState, useEffect } from "react";
import axios from "axios";
import Spinner from "../Spinner.js";
// import './Register.css'
import { Link } from "react-router-dom";
// Import Assets
import video from "../../LoginAssets/work.mp4";
import logo from "../../LoginAssets/itdri.png";
// Import Icon react-icon
import { FaUserShield } from "react-icons/fa";
import { BsFillShieldLockFill } from "react-icons/bs";
import { AiOutlineSwapRight } from "react-icons/ai";
// import { MdMarkEmailRead } from "react-icons/md";
import { FaUser } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const Register = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [colorStatus, setColorStatus] = useState("red");
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [pass, setPass] = useState("");
  const [nama, setNama] = useState("");

  const signUpUser = async (formData, maxAttempts) => {
    let statusStore = "";
    let attempts = 0;
    while (statusStore === "" && attempts < maxAttempts) {
      try {
        const response = await axios.post(
          process.env.REACT_APP_API_URL + "/signup-user/",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            timeout: 36000,
          }
        );
        if (response.data.status && response.data.status.length > 0) {
          let data = response.data.status;
          statusStore = data;
          console.log("Status : >>", statusStore);

          attempts = maxAttempts;
        } else {
          console.log("Data kosong, mencoba lagi...");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }

      attempts += 1;
    }

    if (statusStore === "") {
      console.log(`Gagal Sign Up setelah ${maxAttempts} percobaan.`);
    }

    return statusStore;
  };

  // const [dataUser, setDataUser] = useState([]);

  const handleRegister = (e) => {
    e.preventDefault();
    // console.log("CLICK REGISTER");

    // const formData = new FormData();
    // formData.append("nama", nama);
    // formData.append("email", "");
    // formData.append("username", username);
    // formData.append("password", pass);

    // let statusUser = "";

    // const signUp = async () => {
    //   const hasil = await signUpUser(formData, 5);
    //   statusUser = hasil;
    //   console.log(statusUser);
    // };

    // if (nama === "" || username === "" || pass === "") {
    //   alert("Data tidak boleh kosong.");
    // } else {
    //   signUp();
    //   setLoading(true);

    //   setTimeout(() => {
    //     if (statusUser === "failed") {
    //       console.log("GAGAL");
    //       setColorStatus("red");
    //       setError("USERNAME SUDAH ADA");
    //     } else if (statusUser === "success") {
    //       console.log("SUKSES");
    //       setColorStatus("green");
    //       setError("SUCCESS");

    //       setTimeout(() => {
    //         navigate("/");
    //       }, 1000);
    //     }

    //     setLoading(false);
    //   }, 1000);
    // }
  };

  const text = "Modular Advanced Revamp System\nwith Hyper Learning Loops";

  // Set Show Password
  const [cekPass, setCekPass] = useState(false);

  const onCekPas = (e) => {
    setCekPass(!cekPass);
  };

  return (
    <div className="registerPage flex">
      <div className="container flex">
        <div className="videoDiv">
          <video src={video} autoPlay loop muted></video>

          <div className="textDiv">
            <h2 className="title">Marshall</h2>
            <p style={{ whiteSpace: "pre-line" }}>{text}</p>
          </div>

          <div className="footerDiv flex">
            <span className="text">Have an account?</span>
            <Link to={"/"}>
              <button className="btn">Login</button>
            </Link>
          </div>
        </div>

        <div className="formDiv flex">
          <div className="headerDiv">
            <img src={logo} alt="Logo Image"></img>
            <h3>Let Us Know You!</h3>
          </div>

          {loading ? (
            <Spinner />
          ) : (
            error && (
              <div
                className="loginStatus"
                style={{ backgroundColor: colorStatus }}
              >
                <p style={{ color: "white", textAlign: "center" }}>{error}</p>
              </div>
            )
          )}

          <form action="" className="form grid">
            <div className="inputDiv">
              <label htmlFor="name">Name</label>
              <div className="input flex">
                <FaUser className="icon" />
                <input
                  type="name"
                  id="name"
                  placeholder="Enter Name"
                  onChange={(e) => setNama(e.target.value)}
                />
              </div>
            </div>

            <div className="inputDiv">
              <label htmlFor="username">Username</label>
              <div className="input flex">
                <FaUserShield className="icon" />
                <input
                  type="text"
                  id="username"
                  placeholder="Enter Username"
                  onChange={(e) => setUsername(e.target.value)}
                  value={username}
                />
              </div>
            </div>

            <div className="inputDiv">
              <label htmlFor="password">Password</label>
              <div className="input flex">
                <BsFillShieldLockFill className="icon" />
                <input
                  type={!cekPass ? ("password"):("text")}
                  id="password"
                  placeholder="Enter Password"
                  onChange={(e) => setPass(e.target.value)}
                />
              </div>
            </div>

            <div style={{ display: "flex", justifyContent: "start" }}>
              <input
                type="checkbox"
                checked={cekPass}
                onClick={onCekPas}
                style={{ marginLeft: "5px" }}
              />
              <span style={{ marginLeft: "5px" }}>Show Password</span>
            </div>

            <button type="submit" className="btn flex" onClick={handleRegister}>
              <span>Register</span>
              <AiOutlineSwapRight className="icon" />
            </button>

            {/* <span className="forgotPassword">
              Forgot your password? <a href="">Click Here</a>
            </span> */}
          </form>
        </div>
      </div>
      <span
        style={{
          display: "flex",
          position: "absolute",
          bottom: "0",
          right: "0",
          padding: "10px",
        }}
      >
        ~ Beta Version 1.0
      </span>
    </div>
  );
};

export default Register;
