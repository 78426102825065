import React, { useState, useEffect } from "react";
import "./Review.css";
import Spinner from "./SpinnerDs.js";
import Popup from "./PopUp.js";
import ImagePopup from "./ImagePopUp.js";
import PopupContent from "./PopUpContent.js";
import DropFileInput from "./DropFileInput.js";
import ConfirmationDialog from "./ConfirmationDialog.js";
import { FaCaretUp } from "react-icons/fa";
import { FaCaretDown } from "react-icons/fa";
import logo from "../assets/marshall2.png";
import gifLogo from "../assets/Book.gif";
import axios from "axios";

const Review = (props) => {
  const textjudul = "Review";

  ////////////////////////////////////////////////////////////////////////////
  // Variable Konten Review

  // Hidden Content
  // const [props.kontenVis, setprops.kontenVis] = useState();
  // const [props.loadingVis, setprops.loadingVis] = useState();
  // const [nilaiVis, setNilaiVis] = useState(false);
  // const [props.tableVis, setprops.tableVis] = useState();

  // Inisiasi Interval 3 detik
  const [start, setStart] = useState(false);

  const [butReview, setButReview] = useState("block");
  const [ebookRefVis, setEbookRefVis] = useState(true);
  const [headerShow, setHeaderShow] = useState("flex");

  // Width Drop Files
  const [marginEbook, setMarginEbook] = useState("480");
  const [widthPPT, setWidthPPT] = useState("590");

  // Help Button
  const [showPopupHelp, setPopupHelp] = useState(false);

  // Loading Spinner
  const [loadingSpinner, setLoadingSpinner] = useState(false);
  const [prosesUpload, setProsesUpload] = useState("0");

  ////////////////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////////////////
  // VARIABLE TOKEN DAN STATUS REVIEW

  // const [statusReview, setStatusReview] = useState("");
  // const [tokenReview, setTokenReview] = useState("");
  // const [postStatus, setPostStatus] = useState(false);

  ////////////////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////////////////
  // Variable Post Ke API

  // Variable Options Review
  const [rev1, setRev1] = useState(true);
  const [rev2, setRev2] = useState(true);
  const [autocrawl, setAutoCrawl] = useState(false);

  // Ratio Db
  const [ratioDb, setRatioDb] = useState(100);

  // Variable Upload Files
  const [files, setFiles] = useState("");
  const [filesEbook, setFilesEbook] = useState([]);

  ////////////////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////////////////
  // Variable Table Review

  const [showPopupEyd, setShowPopupEyd] = useState(false);
  const [showPopupClarity, setShowPopupClarity] = useState(false);
  const [showPopupContent, setShowPopupContent] = useState(false);
  const [valueEyd, setValueEyd] = useState("");
  const [valueClarity, setValueClarity] = useState("A~B");
  const [valueContent, setValueContent] = useState("C~D");

  // Content Table
  const [tableReview, setTableReview] = useState([]);

  // SORTING TABLE
  const [clickPage, setClickPage] = useState(false);
  const [clickEyd, setClickEyd] = useState(false);
  const [clickClarity, setClickClarity] = useState(false);
  const [clickContent, setClickContent] = useState(false);

  // DOWNLOAD CONTENT
  const [download, setDownload] = useState("");

  ////////////////////////////////////////////////////////////////////////////

  // LOADING CANCEL CLICK
  const [showConfirmation, setShowConfirmation] = useState(false); // DI HILANGKAN SEMENTARA
  const [showCancel, setShowCancel] = useState(false); // DI HILANGKAN SEMENTARA

  //////////////////////////////////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////////////////////////////////////////
  // FUNCTION

  const filterTable = (table) => {
    console.log("FILTER TABLE");
    var tableStore = table;
    console.log(tableStore);
    // var updateTable = [];
    //console.log(tableStore);

    for (var i = 0; i < tableStore.length; i++) {
      if (tableStore[i].review_eyd.includes("review_word")) {
        var joinString = "";

        var data = tableStore[i].review_eyd;
        var search1 = "review_word";
        var search2 = "explanation";
        var search3 = "recommendation";

        var index1 = data.indexOf(search1);
        var index2 = data.indexOf(search2);
        var index3 = data.indexOf(search3);

        var str1 = "Review Words : " + data.substring(index1 + 15, index2 - 3);
        var str2 = "Explanation : " + data.substring(index2 + 14, index3 - 3);
        var str3 =
          "Recommendation : " +
          data.substring(index3 + 17, tableStore[i].review_eyd.length - 2);

        joinString = [str1, str2, str3].join("~");

        tableStore.forEach((item) => {
          if (item.page === i) {
            item.review_eyd = joinString;
          }
        });

        // console.log("Clarity sudah di proses");
      }

      if (tableStore[i].review_clarity.includes("review_words")) {
        var joinString = "";

        var data = tableStore[i].review_clarity;
        var search1 = "review_words";
        var search2 = "explanation";
        var search3 = "recommendation";

        var index1 = data.indexOf(search1);
        var index2 = data.indexOf(search2);
        var index3 = data.indexOf(search3);

        var str1 = "Review Words : " + data.substring(index1 + 15, index2 - 3);
        var str2 = "Explanation : " + data.substring(index2 + 14, index3 - 3);
        var str3 =
          "Recommendation : " +
          data.substring(index3 + 17, tableStore[i].review_clarity.length - 2);

        joinString = [str1, str2, str3].join("~");

        tableStore.forEach((item) => {
          if (item.page === i) {
            item.review_clarity = joinString;
          }
        });

        // console.log("Clarity sudah di proses");
      }

      // FILTER VALUE KONTEN
      if (tableStore[i].review_content.includes("review_word")) {
        var kondisi = false;
        var arrayBanyakParagraf = [];
        var data = tableStore[i].review_content;

        do {
          var joinString = "";

          var search1 = "'review_word'";
          var search2 = "'explanation'";
          var search3 = "'passing'";
          var search4 = "'review'";
          var search5 = "'recommendation'";

          var index1 = data.indexOf(search1);
          var index2 = data.indexOf(search2);
          var index3 = data.indexOf(search3);
          var index4 = data.indexOf(search4);
          var index5 = data.indexOf(search5);

          var str1 = "Review Word : " + data.substring(index1 + 16, index2 - 3);
          var str2 = "Explanation : " + data.substring(index2 + 16, index3 - 3);
          var str3 = "Passing : " + data.substring(index3 + 12, index4 - 3);
          var str4 = "Review : " + data.substring(index4 + 11, index5 - 3);

          if (str4.includes("\\n")) {
            str4 = str4.replaceAll("\\n", " ");
          }

          var str5 =
            "Recommendation : " + data.substring(index5 + 19, data.length - 3);

          data = data.substring(index5);

          if (data.includes("'review_word'")) {
            search1 = "'review_word'";
            search2 = "'explanation'";
            search3 = "'passing'";
            search4 = "'review'";
            search5 = "'recommendation'";

            index1 = data.indexOf(search1);
            index2 = data.indexOf(search2);
            index3 = data.indexOf(search3);
            index4 = data.indexOf(search4);
            index5 = data.indexOf(search5);

            str5 =
              "Recommendation : " + data.substring(index5 + 19, index1 - 5);

            data = data.substring(index1);

            joinString = [str1, str2, str3, str4, str5].join("||");
            arrayBanyakParagraf = [...arrayBanyakParagraf, joinString];
          } else {
            if (arrayBanyakParagraf.length > 0) {
              joinString = [str1, str2, str3, str4, str5].join("||");
              arrayBanyakParagraf = [...arrayBanyakParagraf, joinString];
            } else {
              joinString = [str1, str2, str3, str4, str5].join("~");
            }

            kondisi = true;
          }
        } while (kondisi != true);

        if (arrayBanyakParagraf.length > 0) {
          joinString = arrayBanyakParagraf.join("~");
        }

        tableStore.forEach((item) => {
          if (item.page === i) {
            item.review_content = joinString;
          }
        });

        // console.log("Konten sudah di proses");
      }
    }

    const newData = tableStore.map((item, index) => ({
      id: index + 1, // Incremental id starting from 1
      page: item.page,
      review_eyd: item.review_eyd,
      review_clarity: item.review_clarity,
      review_content: item.review_content,
      approve: true, // Always set to true
    }));

    // Filter dan ubah [] menjadin Konten sudah oke
    const filterNull = newData.map((item) => {
      if (item.review_content === "[]") {
        return { ...item, review_content: "Konten sudah oke" };
      }
      return item;
    });

    setTableReview(filterNull);
  };

  const getTableReview = (token) => {
    // ===================================================
    // Call List Kompetensi By Nama Grup Kompetensi
    console.log("Panggil Table Review Sesuai TOKEN");

    const formData = new FormData();
    formData.append("request_token", token);
    axios({
      timeout: 360000,
      method: "post",
      url: process.env.REACT_APP_API_URL + "/result-review-module/",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (res) {
        let data = res.data.Review;
        let download = res.data.your_result;

        if (download.length < 1) {
          // Jika data 0, ulangi request setelah 500 milis
          setTimeout(getTableReview, 500);
        } else {
          // Set count request jika data bukan 0
          setDownload(download);
        }

        filterTable(data);
      })
      .catch(function (res) {
        console.log(res);
        return "";
      });

    // ===================================================
  };

  const handleCheckboxChange = (id) => {
    const updatedData = tableReview.map((item) =>
      item.id === id ? { ...item, approve: !item.approve } : item
    );
    // setDataTable(updatedData);
    setTableReview(updatedData);
  };

  const onFileChange = (e) => {
    console.log("Hasil upload PPTX : ", e[0]);
    setFiles(e[0]);

    var div = document.getElementById("notif_text");
    div.style.display = "none";

    setMarginEbook("480");
  };

  const onFileEbookChange = (e) => {
    console.log("Hasil upload Ebook : ", e);
    let mapData = [];
    let stringStore = "";
    let loops = 0;

    setFilesEbook(e);

    // if (e.length > 0) {
    //   do {
    //     stringStore = e[loops];
    //     console.log("String store : ", stringStore);
    //     mapData.push(stringStore);
    //     loops++;
    //   } while (loops !== e.length);
    //   console.log("Map Data : ", mapData);
    //   setFilesEbook(mapData);
    // } else {
    //   setFilesEbook([]);
    // }

    // if (e && e.type !== 'application/pdf') {
    //   alert('Hanya file dengan format .pdf yang diperbolehkan.');
    //   e.target.value = null; // Mengosongkan input jika file tidak valid
    //   return;
    // }

    // if (e) {
    //   setFilesEbook(e);
    //   // Lanjutkan dengan logika lain jika diperlukan
    // }

    // var div = document.getElementById("notif_text");
    // div.style.display = "none";
  };

  // Function Options Review
  const onCheckRef1 = () => {
    if (!rev2) {
      setRev1(true);
    } else {
      // rev1 ? setRev1(false) : setRev1(true);
      if (rev1) {
        setRev1(false);
        setEbookRefVis(false);
        setWidthPPT("1200");
      } else {
        setRev1(true);
        setEbookRefVis(true);
        setWidthPPT("590");
      }
    }
  };

  const onCheckRef2 = () => {
    if (!rev1) setRev2(true);
    else rev2 ? setRev2(false) : setRev2(true);
  };

  const onCheckCrawl = () => {
    autocrawl ? setAutoCrawl(false) : setAutoCrawl(true);
  };

  // const onCheckNilai = () => {
  //   if (!valtable) setValNilai(true);
  //   else valnilai ? setValNilai(false) : setValNilai(true);
  // };

  // const onCheckTable = () => {
  //   if (!valnilai) setValTable(true);
  //   else valtable ? setValTable(false) : setValTable(true);
  // };

  ////////////////////////////////////////////////////////////////////////////////////////
  // FUNCTION POP UP
  const onClickHelp = (e) => {
    e.preventDefault();

    setPopupHelp(!showPopupHelp);
  };

  // PERLU UNTUK SET VALUE DI POP UP
  const onClickEyd = (e, user) => {
    e.preventDefault();

    setValueEyd(user.review_eyd);
    setShowPopupEyd(!showPopupEyd);
  };

  const onClickClarity = (e, user) => {
    e.preventDefault();

    setValueClarity(user.review_clarity);
    setShowPopupClarity(!showPopupClarity);
  };

  const onClickContent = (e, user) => {
    e.preventDefault();

    setValueContent(user.review_content);
    setShowPopupContent(!showPopupContent);
  };

  // PERLU UNTUK HANDLE CLICK CLOSE AGAR TIDAK BENTROK DENGAN DI ONCLICK CLARITY/CONTENT
  const onClickEyd2 = (e) => {
    e.preventDefault();
    setShowPopupEyd(!showPopupEyd);
  };

  const onClickClarity2 = (e) => {
    e.preventDefault();
    setShowPopupClarity(!showPopupClarity);
  };

  const onClickContent2 = (e) => {
    e.preventDefault();

    setShowPopupContent(!showPopupContent);
  };

  ///////////////////////////////////////////////////////////////////////////////////////

  // Content Nilai
  // const onClickNilai = () => {
  //   if (valtable) {
  //     setprops.tableVis(true);
  //     setprops.kontenVis(false);
  //     setNilaiVis(false);
  //   } else {
  //     //
  //   }
  // };

  //////////////////////////////////////////////////////////////////////////////////////
  // CLICK

  const handleCancelReview = (e) => {
    e.preventDefault();
    setShowConfirmation(true);
  };

  const handleConfirm = (e) => {
    // Lakukan aksi jika pengguna mengkonfirmasi
    e.preventDefault();

    console.log("Proses Review dibatalkan");
    setShowConfirmation(false);

    // setprops.kontenVis(true);
    // setprops.loadingVis(false);
    // setprops.tableVis(false);
    props.toggleVisReview("content", true);
    props.toggleVisReview("loading", false);
    props.toggleVisReview("table", false);

    setHeaderShow("flex");
  };

  const handleCancel = (e) => {
    // Lakukan aksi jika pengguna membatalkan konfirmasi
    e.preventDefault();

    console.log("Pembatalan Proses Review dibatalkan");
    setShowConfirmation(false);
  };

  const handlePageClick = () => {
    var div = document.getElementById("icon_pagedown");
    var div2 = document.getElementById("icon_pageup");

    if (clickPage === false) {
      div.style.display = "none";
      div2.style.display = "inline";

      const sorted = [...tableReview].sort((a, b) =>
        a["page"] > b["page"] ? 1 : -1
      );
      // setDataTable(sorted);
      setTableReview(sorted);
    } else {
      div.style.display = "inline";
      div2.style.display = "none";

      const sorted = [...tableReview].sort((a, b) =>
        b["page"] > a["page"] ? 1 : -1
      );
      // setDataTable(sorted);
      setTableReview(sorted);
    }

    clickPage ? setClickPage(false) : setClickPage(true);
  };

  const handleTextClick = (col) => {
    //e.preventDefault();
    var div = document.getElementById("icon_eyddown");
    var div2 = document.getElementById("icon_eydup");

    var div3 = document.getElementById("icon_claritydown");
    var div4 = document.getElementById("icon_clarityup");

    var div5 = document.getElementById("icon_contentdown");
    var div6 = document.getElementById("icon_contentup");

    if (col === "eyd") {
      if (clickEyd === false) {
        div.style.display = "none";
        div2.style.display = "inline";

        const sorted = [...tableReview].sort((a, b) =>
          a[col] > b[col] ? 1 : -1
        );
        // setDataTable(sorted);
        setTableReview(sorted);
      } else {
        div.style.display = "inline";
        div2.style.display = "none";

        const sorted = [...tableReview].sort((a, b) =>
          b[col] > a[col] ? 1 : -1
        );
        // setDataTable(sorted);
        setTableReview(sorted);
      }

      clickEyd ? setClickEyd(false) : setClickEyd(true);
    } else if (col === "clarity") {
      if (clickClarity === false) {
        div3.style.display = "none";
        div4.style.display = "inline";

        const sorted = [...tableReview].sort((a, b) =>
          a[col] > b[col] ? 1 : -1
        );
        // setDataTable(sorted);
        setTableReview(sorted);
      } else {
        div3.style.display = "inline";
        div4.style.display = "none";

        const sorted = [...tableReview].sort((a, b) =>
          b[col] > a[col] ? 1 : -1
        );
        // setDataTable(sorted);
        setTableReview(sorted);
      }

      clickClarity ? setClickClarity(false) : setClickClarity(true);
    } else if (col === "content") {
      if (clickContent === false) {
        div5.style.display = "none";
        div6.style.display = "inline";

        const sorted = [...tableReview].sort((a, b) =>
          a[col] > b[col] ? 1 : -1
        );
        // setDataTable(sorted);
        setTableReview(sorted);
      } else {
        div5.style.display = "inline";
        div6.style.display = "none";

        const sorted = [...tableReview].sort((a, b) =>
          b[col] > a[col] ? 1 : -1
        );
        // setDataTable(sorted);
        setTableReview(sorted);
      }

      clickContent ? setClickContent(false) : setClickContent(true);
    }
  };

  const [errorMsg, setErrorMsg] = useState("");

  // const reviewModule = async (formData, maxAttempts) => {
  //   let statusStore = "";
  //   let attempts = 0;

  //   while (statusStore === "" && attempts < maxAttempts) {
  //     try {
  //       if (attempts === 0) {
  //         const response = await axios.post(
  //           process.env.REACT_APP_API_URL + "/new-review-module/",
  //           formData,
  //           {
  //             headers: {
  //               "Content-Type": "multipart/form-data",
  //             },
  //             timeout: 0,
  //           }
  //         );
  //         if (
  //           response.data.your_request_token &&
  //           response.data.your_request_token.length > 0
  //         ) {
  //           let data = response.data.your_request_token;
  //           statusStore = data;
  //           // console.log("TOKEN REVIEW NYA : ", data);

  //           attempts = maxAttempts;
  //         } else {
  //           console.log("Gagal Review, mencoba lagi...");
  //         }
  //       } else {
  //         setTimeout(async () => {
  //           const response = await axios.post(
  //             process.env.REACT_APP_API_URL + "/new-review-module/",
  //             formData,
  //             {
  //               headers: {
  //                 "Content-Type": "multipart/form-data",
  //               },
  //               timeout: 0,
  //             }
  //           );
  //           if (
  //             response.data.your_request_token &&
  //             response.data.your_request_token.length > 0
  //           ) {
  //             let data = response.data.your_request_token;
  //             statusStore = data;
  //             // console.log("TOKEN REVIEW NYA : ", data);

  //             attempts = maxAttempts;
  //           } else {
  //             console.log("Gagal Review, mencoba lagi...");
  //           }
  //         }, 5000);
  //       }
  //     } catch (error) {
  //       console.error("Error message:", error.message);
  //       if (error.response) {
  //         console.error("Server responded with:", error.response.status);
  //         setErrorMsg("Server responded with:", error.response.status);
  //       } else if (error.request) {
  //         console.error("No response received:", error.request);
  //         setErrorMsg("No response received:", error.request);
  //       } else {
  //         console.error("Axios config error:", error.message);
  //         setErrorMsg("Axios config error:", error.message);
  //       }
  //     }

  //     attempts += 1;
  //   }

  //   if (statusStore === "") {
  //     console.log(`Gagal Review Module setelah ${maxAttempts} percobaan.`);
  //   }

  //   return statusStore;
  // };

  const reviewModule = async (formData, maxAttempts) => {
    let statusStore = "";
    let attempts = 0;

    const review = async () => {
      try {
        const response = await axios.post(
          process.env.REACT_APP_API_URL + "/new-review-module/",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            timeout: 1200000 , // 20 Menit
            onUploadProgress: (progressEvent) => {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              console.log(`Progress: ${percentCompleted}%`);
              if (percentCompleted < 60) {
                setLoadingSpinner(true);
                setProsesUpload(percentCompleted);
              }
              // Anda bisa menggunakan setState atau fungsi lain untuk memperbarui UI di sini
            },
          }
        );

        if (
          response.data.your_request_token &&
          response.data.your_request_token.length > 0
        ) {
          let data = response.data.your_request_token;
          statusStore = data;
          // console.log("TOKEN REVIEW NYA : ", data);

          attempts = maxAttempts;
        } else {
          console.log("Gagal Review, mencoba lagi...");
        }
      } catch (error) {
        console.error("Error message:", error.message);

        if (error.response) {
          console.error("Server responded with:", error.response.status);
          setErrorMsg("Server responded with:", error.response.status);
        } else if (error.request) {
          console.error("No response received:", error.request);
          setErrorMsg("No response received:", error.request);
        } else {
          console.error("Axios config error:", error.message);
          setErrorMsg("Axios config error:", error.message);
        }

        return false; // Error, akan mencoba lagi
      }
    };

    while (statusStore === "" && attempts < maxAttempts) {
      attempts += 1;
      const isSuccessful = await review();

      if (isSuccessful) {
        break;
      } else if (attempts < maxAttempts) {
        console.log(
          `Menunggu 5 detik sebelum mencoba lagi (${attempts}/${maxAttempts})...`
        );
        await new Promise((resolve) => setTimeout(resolve, 5000));
      }
    }

    if (statusStore === "") {
      console.log(`Gagal Review Module setelah ${maxAttempts} percobaan.`);
    }

    return statusStore;
  };

  //(KHUSUS REVIEW BUTTON)
  const handleClick = (e) => {
    e.preventDefault();

    var notif = document.getElementById("notif_text");

    // CEK FILE
    if (files.length === 0) {
      notif.style.display = "inline";
      setMarginEbook("239");
    } else {
      notif.style.display = "none";
      setMarginEbook("480");

      //POST REVIEW MODULE
      console.log("Post Review");

      props.toggleVisReview("loading", false);
      props.toggleVisReview("content", false);

      const formData = new FormData();
      formData.append("file_ppt", files);
      formData.append("check_review_1", rev1);
      formData.append("check_review_2", rev2);
      if (filesEbook.length > 0) {
        console.log("Ada File Ebook : ", filesEbook.length);
        for (let i = 0; i < filesEbook.length; i++) {
          formData.append("file_ebooks", filesEbook[i]);
          console.log(i + " : " + filesEbook[i]);
        }
      }
      formData.append("crawl_internet", autocrawl);
      formData.append("db_knowledge", ratioDb / 100);
      formData.append("id_user", props.userid);

      const review = async () => {
        const hasil = await reviewModule(formData, 5);
        if (hasil === "" || hasil === null) {
          alert("GAGAL REVIEW MODULE => " + errorMsg);
        } else {
          setLoadingSpinner(false);
          setStart(true);
          props.toggleVisReview("token", hasil);
          setHeaderShow("flex");

          props.toggleVisReview("content", false);
          props.toggleVisReview("loading", true);
          props.toggleVisReview("table", false);
        }
      };

      review();
    }
  };

  const clickBaru = (e) => {
    e.preventDefault();

    // Konten Null
    setRev1(true);
    setRev2(true);
    setAutoCrawl(false);
    setRatioDb(100);
    setFiles("");
    setFilesEbook([]);

    props.resetToken("RESET");
    props.resetStatus("RESET");

    props.toggleVisReview("token", "RESET");
    props.toggleVisReview("status", "RESET");

    setHeaderShow("flex");
    props.toggleVisReview("content", true);
    props.toggleVisReview("loading", false);
    props.toggleVisReview("table", false);

    props.getOverflow("hidden");
  };

  // Set Download Spinner
  const [isLoading, setIsLoading] = useState(false);

  const click_downloadRevisi = () => {
    setIsLoading(true);
    setTimeout(() => {
      // Setelah beberapa waktu, sembunyikan spinner dan tampilkan kembali tombol
      const link = document.createElement("a");
      link.href = download;
      document.body.appendChild(link);
      link.click();
      link.remove();

      setIsLoading(false);
    }, 2000); // Ganti dengan waktu proses Anda
  };

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // USE EFFECT

  // Ambil Status Review per interval 3 detik (KHUSUS REVIEW BUTTON)
  const fetchData = async () => {
    const formData = new FormData();
    formData.append("request_token", props.token);

    // console.log("Token Mapping : ", tokenMapping);
    try {
      const res = await axios.post(
        process.env.REACT_APP_API_URL + "/result-review-module/",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          timeout: 360000, // Timeout diatur ke 0 untuk menunggu sampai selesai
        }
      );
      let data = res.data.status;
      let download = res.data.your_result;
      // console.log("Ini Download nya : ", download);

      console.log("Status Request : ", data);

      if (data === "done") {
        if (download.length < 1) {
          // Jika data 0, ulangi request setelah 500 milis
          setTimeout(getTableReview, 500);
        } else {
          // Set count request jika data bukan 0
          setDownload(download);
        }
        props.toggleVisReview("status", data);
        console.log("Proses Selesai");
        setStart(false);
      }
    } catch (error) {
      console.error("Error saat memanggil API:", error);
    }
  };

  useEffect(() => {
    let interval;

    if (start) {
      interval = setInterval(async () => {
        await fetchData(); // Call the fetchData function here
      }, 3000);
    }

    return () => clearInterval(interval);
  }, [start, props.token]);

  // Settings Loading dengan Status
  useEffect(() => {
    console.log("Settings Loading dengan Status");
    console.log("+" + props.status + "+");
    if (props.status === "done") {
      console.log("DONEEEEEEEEEEEEEEEEEEEE");
      // setprops.kontenVis(false);
      // setprops.loadingVis(false);
      // setprops.tableVis(true);
      props.toggleVisReview("content", false);
      props.toggleVisReview("loading", false);
      props.toggleVisReview("table", true);
      setHeaderShow("block");

      getTableReview(props.token);

      props.getOverflow("scroll");
    }
    // Jika selain "Done"
    else if (props.status === "processing") {
      // setShowCancel(false);

      console.log("PROCESIINGGGGGGGGGGG");

      props.toggleVisReview("content", false);
      props.toggleVisReview("loading", true);
      props.toggleVisReview("table", false);

      setHeaderShow("flex");
    } else if (props.status === "waiting") {
      console.log("WAITINGGGGGGGGGGGGGGGGGG");
      props.toggleVisReview("content", false);
      props.toggleVisReview("loading", true);
      props.toggleVisReview("table", false);
      // setNilaiVis(false);

      setHeaderShow("flex");
    } else if (props.status === "error") {
      // setShowCancel(false);

      console.log("ERROOOOOOORRRR");
      props.toggleVisReview("content", true);
      props.toggleVisReview("loading", false);
      props.toggleVisReview("table", false);
      // setNilaiVis(false);

      setHeaderShow("flex");
      alert("STATUS ERROR : MODULE BERMASALAH");
    } else if (props.status === "RESET") {
      console.log("INI YANG KOSONGGGGGGGGGG");
      // setprops.loadingVis(true);
      // setprops.tableVis(false);
      // setprops.kontenVis(false);
      props.toggleVisReview("content", true);
      props.toggleVisReview("loading", false);
      props.toggleVisReview("table", false);
      setHeaderShow("flex");
    }
  }, [props.status]);

  // SET SHOW TABLE DARI REQUEST

  const fetchDataReq = async () => {
    const formData = new FormData();
    formData.append("request_token", props.tokenCoqa);

    console.log("Detail Mapping : ", props.detailCoqa);

    // console.log("Token Mapping : ", tokenCoqa);
    try {
      const res = await axios.post(
        process.env.REACT_APP_API_URL + "/result-review-module/",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          timeout: 360000, // Timeout diatur ke 0 untuk menunggu sampai selesai
        }
      );
      let data = res.data.status;
      let download = res.data.your_result;
      // console.log("Ini Download nya : ", download);

      console.log("Status Request : ", data);

      if (data === "done") {
        if (download.length < 1) {
          // Jika data 0, ulangi request setelah 500 milis
          setTimeout(getTableReview, 500);
        } else {
          // Set count request jika data bukan 0
          setDownload(download);
        }
        props.toggleVisReview("status", data);
        console.log("Proses Selesai");
        setStart(false);
      }
    } catch (error) {
      console.error("Error saat memanggil API:", error);
    }
  };

  useEffect(() => {
    // console.log("INI TOKEN REQQQQQQQQQ : " + props.tokenReq);
    console.log("INI STATUS REQQQQQQQQ : " + props.statusReq);
    console.log("LOADING VIS : ", props.loadingVis);
    // console.log("LOADING VIS PROPS : ", props.loadingVis);

    // setTokenReview(props.tokenReq);
    if (props.tokenReq.length > 5) {
      props.toggleVisReview("token", props.tokenReq);
      props.toggleVisReview("status", props.statusReq);
    }

    setTimeout(() => {
      if (props.tokenReq.length > 5) {
        if (props.statusCoqa !== "done") {
          setStart(true);
        } else {
          fetchDataReq();
        }
        // setStatusReview(props.statusReq);
        console.log("ADA TOKEN REQ NIHHH");

        if (props.statusReq === "error") {
          setHeaderShow("flex");
          props.toggleVisReview("content", true);
          props.toggleVisReview("loading", false);
          props.toggleVisReview("table", false);

          alert("STATUS REVIEW MODULE ERROR");
          props.resetToken("RESET");
          props.resetStatus("RESET");
        } else if (props.statusReq === "processing") {
          props.toggleVisReview("content", false);
          props.toggleVisReview("loading", true);
          props.toggleVisReview("table", false);
          // setNilaiVis(false);

          setHeaderShow("flex");
        } else if (props.statusReq === "waiting") {
          props.toggleVisReview("content", false);
          props.toggleVisReview("loading", true);
          props.toggleVisReview("table", false);
          // setNilaiVis(false);

          setHeaderShow("flex");
        } else if (props.statusReq === "done") {
          getTableReview(props.tokenReq);
          props.getOverflow("scroll");
          props.toggleVisReview("content", false);
          props.toggleVisReview("loading", false);
          props.toggleVisReview("table", true);

          setHeaderShow("block");
        } else if (props.statusReq === "RESET") {
          // setNilaiVis(false);

          setHeaderShow("flex");
          props.toggleVisReview("content", true);
          props.toggleVisReview("loading", false);
          props.toggleVisReview("table", false);
        }
      }
    }, 1000); // penundaan 1 detik
  }, [props.tokenReq]);

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////

  // KETIKA DI REQUEST KLIK SHOW
  useEffect(() => {
    console.log("DARI REVIEW : SHOW REQUEST TELAH DI KLIK : ", props.reqShow);

    if (props.reqShow) {
      console.log("MASUK REQ SHOWWWWWWWWWWWWWWWWWWWWWWWWWW");
      console.log("Loading Vis : ", props.loadingVis);
      console.log("Konten Vis : ", props.kontenVis);
      console.log("Table Vis : ", props.tableVis);
      props.toggleVisReview("content", false);
      setHeaderShow("flex");
    }
  }, [props.reqShow]);

  const testClick = (e) => {
    e.preventDefault();

    // console.log(filesEbook);
    console.log(ratioDb);
    // console.log(filesEbook.length);
  };

  // RATIO DB
  const ratioDbChange = (e) => {
    if (e.target.value > 100) {
      // openPopup();
      setRatioDb(100);
    } else if (e.target.value < 0) {
      // openPopup();
      setRatioDb(100);
    } else {
      setRatioDb(e.target.value);
    }
  };

  const handleBlur = (e) => {
    if (e.target.value === "") {
      setRatioDb(100);
    }
  };

  // Pop Up Image
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <div
      className="reviewDash"
      style={{
        display: headerShow,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {isPopupOpen && (
        <ImagePopup
          imageUrl="https://i.pinimg.com/736x/54/ee/35/54ee3555d6a08cd36bcd74ddd3c3c0ea.jpg" // Ganti dengan URL gambar yang kamu inginkan
          onClose={closePopup}
        />
      )}

      {/* <div className="headers-review" style={{ display: headerShow }}>
            <h1 id="textRev">Review Modul</h1>
      </div> */}
      {props.kontenVis && (
        <div className="main-content-review">
          {/* <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
            <h1 style={{fontSize:"30px"}}>Sedang Perbaikan..</h1>
          </div> */}
          <div className="headers-review">
            <h1 id="textRev">Review Modul</h1>
          </div>
          <h1>Options Review</h1>

          <div className="main-section-review">
            <div className="settings-section">
              <label style={{ "font-size": "17px" }}>Settings</label>
              <div className="checkBox-div">
                <input
                  className="checkBox_revItem"
                  type="checkbox"
                  checked={rev1}
                  onClick={onCheckRef1}
                />
                <span
                  className="boxText_revItem"
                  style={{ "font-size": "15px" }}
                >
                  Review 1
                </span>
              </div>

              <div className="checkBox-div">
                <input
                  className="checkBox_revItem"
                  type="checkbox"
                  checked={rev2}
                  onClick={onCheckRef2}
                />
                <span
                  className="boxText_revItem"
                  style={{ "font-size": "15px" }}
                >
                  Review 2
                </span>
              </div>

              <div className="checkBox-div">
                <input
                  className="checkBox_revItem"
                  type="checkbox"
                  checked={autocrawl}
                  onClick={onCheckCrawl}
                />
                <span
                  className="boxText_revItem"
                  style={{ "font-size": "15px" }}
                >
                  Auto Crawling&nbsp;
                </span>
                <button className="helpBut" onClick={onClickHelp}>
                  ?
                </button>
                <Popup
                  handleClose={onClickHelp}
                  show={showPopupHelp}
                  judulContent={"Help - Auto Crawling"}
                  isiContent={
                    "Aktifkan jika ingin menambahkan Referensi di luar Ebook yang ada. (Menambah Waktu Proses Review)"
                  }
                />
              </div>
            </div>

            <div style={{ display: "grid", height: "50px" }}>
              <label style={{ "font-size": "17px" }} onClick={testClick}>
                Ratio Database
              </label>
              <div
                style={{ display: "flex", fontSize: "14px" }}
                onClick={testClick}
              >
                <input
                  className="ratiodb_review"
                  type="number"
                  value={ratioDb}
                  max={100}
                  min={0}
                  style={{ textAlign: "center" }}
                  onChange={ratioDbChange}
                  onBlur={handleBlur}
                  onKeyDown={(e) => {
                    if (["e", ".", ","].includes(e.key)) {
                      e.preventDefault();
                    }
                  }}
                ></input>
                <span>&nbsp;%</span>
              </div>
            </div>
          </div>

          <div className="text-footer">
            <span style={{ "font-size": "17px" }}>File Module PPT</span>
            <span style={{ color: "red", "font-size": "18px" }}> *</span>
            <span
              id="notif_text"
              style={{ display: "none", "font-size": "14px", color: "red" }}
            >
              {" "}
              (Inputkan File PPT Terlebih Dahulu)
            </span>

            {ebookRefVis && (
              <div
                className="footer_module_review"
                style={{ marginLeft: marginEbook + "px" }}
              >
                <span style={{ "font-size": "17px" }}>
                  File Ebook Reference PDF (Optional)
                </span>
              </div>
            )}
          </div>

          <div className="dropFile_review">
            <DropFileInput
              onFileChange={onFileChange}
              textjudul={textjudul}
              width={widthPPT}
              height="100"
              widthlogo="25"
              logoName="pptx.png"
              dropName="PPTX"
              accept=".pptx"
              multiple={false}
            />

            {ebookRefVis && (
              <div className="dropFile_ebook_review">
                <DropFileInput
                  onFileChange={(e) => onFileEbookChange(e)}
                  textjudul={textjudul}
                  width="590"
                  height="100"
                  widthlogo="25"
                  logoName="pdf.png"
                  dropName="PDF"
                  accept=".pdf"
                  multiple={true}
                />
              </div>
            )}
          </div>

          <div className="footer-review">
            <button className="reviewBut" onClick={(e) => handleClick(e)}>
              Review
            </button>
          </div>
        </div>
      )}

      {loadingSpinner && (
        <div style={{ display: "grid", justifyContent: "center" }}>
          <span style={{ fontSize: "15px" }}>
            Masih Belum Masuk Antrian (Tunggu Upload Selesai)
          </span>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span style={{ fontSize: "15px" }}>
              Progress Upload : {prosesUpload}% &nbsp;&nbsp;&nbsp;
            </span>
            <Spinner />
          </div>
        </div>
      )}

      {props.loadingVis && (
        <div className="loading-container">
          <div
            className="logo-spinner"
            // style={{ display: "flex", marginTop: "170px" }}
          >
            <img
              className="logo-loading"
              src={logo}
              style={{ width: "320px", height: "120px", marginLeft: "110px" }}
            ></img>
            <img
              className="gif-loading"
              src={gifLogo}
              style={{ width: "60px", height: "60px", marginLeft: "10px" }}
            ></img>
          </div>

          <p
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              textAlign: "center",
              fontSize: "15px",
              whiteSpace: "pre-line",
            }}
          >
            {props.status === "waiting"
              ? "Tunggu Antrean Review Module (Waiting)\nStatus Request Anda dapat di CEK di Tab Requests (Di Navigasi Panel Sebelah Kiri)"
              : "Review Module Anda sedang di Proses (Processing)\nStatus Request Anda dapat di CEK di Tab Requests (Di Navigasi Panel Sebelah Kiri)"}
          </p>

          <div className="footer-loading">
            {showCancel && (
              <button
                className="cancelBut_loading"
                onClick={handleCancelReview}
              >
                Cancel
              </button>
            )}

            <button className="reviewBaru_but" onClick={clickBaru}>
              REVIEW BARU
            </button>

            {showConfirmation && (
              <ConfirmationDialog
                message="Apakah anda akan cancel proses Review?"
                onConfirm={handleConfirm}
                onCancel={handleCancel}
              />
            )}
          </div>
        </div>
      )}

      {/* {nilaiVis && (
        <div className="main-content-nilai">
          <span className="header-nilai">Hasil Nilai Review :</span>
          <div className="container-nilai">
            <span className="hasilNilai">59,8%</span>
          </div>
          <button className="nilaiButton" onClick={onClickNilai}>
            {textNilai}
          </button>
        </div>
      )} */}

      {props.tableVis && (
        //
        <div className="table_container">
          <div className="headers-review" style={{ marginTop: "90px" }}>
            <h1 id="textRev">Review Modul</h1>
          </div>
          <div className="headerTable_content">
            {/* <span style={{ "font-size": "12px" }} onClick={filterTable}>
              Score Module :{" "}
            </span>
            <span style={{ "font-size": "12px", color: "green" }}>59,8%</span> */}

            <button className="reviewBaru_but" onClick={clickBaru}>
              REVIEW BARU
            </button>

            {!isLoading ? (
              <button
                className="download_but"
                onClick={click_downloadRevisi}
                style={{ display: download.length < 5 ? "none" : "flex" }}
              >
                Download Revisi PPT
              </button>
            ) : (
              <div
                style={{
                  width: "200px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Spinner />
              </div>
            )}
          </div>

          <table class="table_review">
            <thead>
              <tr>
                <th scope="col" onClick={handlePageClick}>
                  Page &nbsp;
                  <FaCaretDown
                    id="icon_pagedown"
                    style={{ display: "inline" }}
                  />
                  <FaCaretUp id="icon_pageup" style={{ display: "none" }} />
                </th>
                <th scope="col" onClick={() => handleTextClick("eyd")}>
                  EYD &nbsp;
                  <FaCaretDown
                    id="icon_eyddown"
                    style={{ display: "inline" }}
                  />
                  <FaCaretUp id="icon_eydup" style={{ display: "none" }} />
                </th>
                <th scope="col" onClick={() => handleTextClick("clarity")}>
                  Clarity &nbsp;
                  <FaCaretDown
                    id="icon_claritydown"
                    style={{ display: "inline" }}
                  />
                  <FaCaretUp id="icon_clarityup" style={{ display: "none" }} />
                </th>
                <th scope="col" onClick={() => handleTextClick("content")}>
                  Content &nbsp;
                  <FaCaretDown
                    id="icon_contentdown"
                    style={{ display: "inline" }}
                  />
                  <FaCaretUp id="icon_contentup" style={{ display: "none" }} />
                </th>
                <th>Approvement</th>
              </tr>
            </thead>
            <tbody>
              {tableReview.map((user) => (
                <tr key={user.page}>
                  <td style={{ textAlign: "center", width: "80px" }}>
                    {user.page + 1}
                  </td>
                  <td
                    style={{
                      width: "300px",
                      color: user.review_eyd.includes("Review Words")
                        ? "red"
                        : "black",
                    }}
                    onClick={(e) => onClickEyd(e, user)}
                  >
                    {user.review_eyd.includes("Review Words") ? (
                      <div id="table_p">
                        {user.review_eyd.split("~").map((data) => (
                          <div>
                            <p>{data.substring(0, 30)}...</p>
                          </div>
                        ))}
                      </div>
                    ) : (
                      user.review_eyd
                    )}

                    <Popup
                      handleClose={onClickEyd2}
                      show={showPopupEyd}
                      judulContent={"Details EYD"}
                      isiContent={
                        <div>
                          {valueEyd.split("~").map((data) => (
                            <ul style={{ listStyle: "inside" }}>
                              <li>{data}</li>
                            </ul>
                          ))}
                        </div>
                      }
                    />
                  </td>
                  <td
                    style={{
                      width: "400px",
                      color: user.review_clarity.includes("Review Words")
                        ? "red"
                        : "black",
                    }}
                    onClick={(e) => onClickClarity(e, user)}
                  >
                    {user.review_clarity.includes("Review Words") ? (
                      <div id="table_p">
                        {user.review_clarity.split("~").map((data) => (
                          <div>
                            <p>{data.substring(0, 30)}...</p>
                          </div>
                        ))}
                      </div>
                    ) : (
                      user.review_clarity
                    )}

                    <Popup
                      handleClose={onClickClarity2}
                      show={showPopupClarity}
                      judulContent={"Details Clarity"}
                      isiContent={
                        <div>
                          {valueClarity.split("~").map((data) => (
                            <ul style={{ listStyle: "inside" }}>
                              <li>{data}</li>
                            </ul>
                          ))}
                        </div>
                      }
                    />
                  </td>
                  <td
                    style={{
                      width: "400px",
                      color: user.review_content.includes("Review Word")
                        ? "red"
                        : "black",
                    }}
                    onClick={(e) => onClickContent(e, user)}
                  >
                    {user.review_content.includes("Review Word") ? (
                      <div id="table_p">
                        {user.review_content.split("~").map((data, index) => (
                          <div>
                            {data.includes("||") && index < 3 ? (
                              <span>Kalimat ke - {index + 1}</span>
                            ) : (
                              ""
                            )}

                            {index < 3 ? <p>{data.substring(0, 30)}...</p> : ""}
                          </div>
                        ))}
                      </div>
                    ) : (
                      user.review_content
                    )}

                    <PopupContent
                      handleClose={onClickContent2}
                      show={showPopupContent}
                      judulContent={"Details Content"}
                      isiContent={
                        <div>
                          {valueContent.split("~").map((data, index) => (
                            <div>
                              {data.includes("||") ? (
                                <p>Kalimat ke - {index + 1}</p>
                              ) : (
                                ""
                              )}

                              {data.includes("||") ? (
                                data.split("||").map((item) => (
                                  <ul style={{ listStyle: "inside" }}>
                                    <li>{item}</li>
                                  </ul>
                                ))
                              ) : (
                                <ul style={{ listStyle: "inside" }}>
                                  <li>{data}</li>
                                </ul>
                              )}
                              <br></br>
                            </div>
                          ))}
                        </div>
                      }
                    />
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {
                      <input
                        type="checkbox"
                        checked={user.approve}
                        onClick={() => handleCheckboxChange(user.id)}
                      />
                    }
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* <ul className="pagination_review">
            <li>&laquo;</li>
            <li>&lsaquo;</li>
            <li>1</li>
            <li>&rsaquo;</li>
            <li>&raquo;</li>
          </ul> */}
        </div>
      )}
    </div>
  );
};

export default Review;
